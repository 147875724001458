<!-- 首页 -->

<template>
    <div>
        <!-- 头部 -->
        <common-nav @clickBtn="toFooterWrap" />

        <!-- 广告 -->
        <custom-banner :data="bannerData" :mode="2" layout="left" theme="#04CB94" text_color="black" :border-radius="50"
            :is-cns="isCns" @clickBtn="toFooterWrap"></custom-banner>

        <!-- 四块卡片 -->
        <div class="f2-box">
            <div class="f2-wrap container">
                <div class="item" v-for="(item, index) in dataF2" :key="index" @mouseenter="currF2Idx = index">
                    <img class="item-bg" :src="item.img" alt="">
                    <img class="item-bg-ac" :src="item.imgAc" alt="">
                    <div class="content">
                        <h3>{{ item.title }}</h3>
                        <span>{{ item.content }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="f3-box">
            <div class="box-title">
                <h1>{{ $t('home.f3_box_title') }}</h1>
                <h2>{{ $t('home.f3_box_text') }}</h2>
            </div>
            <img :src="$currentLanguage == 'en' ? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/875fc7ef-121c-4463-9f3e-3a6467e7bcb4.png' :
              $currentLanguage == 'zh-hk' ? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f64c3aa4-015d-4cca-9265-43573516cb86.png' :
              'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/13ae4af7-38fa-4d28-ae6b-c7879feec2e6.png'"
                alt="">
        </div>

        <div class="f4-box">
            <div class="box-title">
                <h1>{{ $t('home.f4_box_title') }}</h1>
                <h2>{{ $t('home.f4_box_text') }}</h2>
            </div>
            <div class="f4-wrap container">
                <div style="display:flex">
                    <div class="jinyingleft">
                    <div class="item" v-for="(tab, tabIdx) in dataF4" :key="tabIdx" :class="[tabIdx == currF4Idx?'changeitem':'']" @mouseenter="currF4Idx = tabIdx">
                      <div class="items">
                           <div style="display:flex;align-items: center;">
                               <img :src="tab.icon" alt="" class="icons">
                               <span :class="{'sapn-active': tabIdx == currF4Idx}">
                                {{ tab.title }}
                               </span>
                           </div>
                           <span class="tos" v-if="tabIdx == currF4Idx">{{tab.desc}}</span>
                      </div>
                    </div>
                </div>
                <div class="jinyingright">
                    <div class="box" >
                         <img :src="dataF4[currF4Idx].img" alt="">
                         <div class="btn" @click="toFooterWrap">{{$t('home.experienceNow')}}</div>
                    </div>
                </div>
                </div>
              
            </div>
        </div>

        <div class="f7-box">
            <div class="box-title">
                <h1>{{ $t('home.f7_box_title') }}</h1>
                <h2 style="letter-spacing: 1px;">{{ $t('home.f7_box_text') }}</h2>
            </div>
            <div class="f7-wrap container">
                <div class="tab-box">
                    <div v-for="(tab, tabIdx) in dataF7" :key="tabIdx"
                        :class="['tab-item', { 'tab-active': tabIdx === currF7Idx }]"
                        @mouseenter="$refs['refF7'].setActiveItem(tabIdx)">{{ tab.title }}</div>
                </div>
                <el-carousel ref="refF7" arrow="never" indicator-position="none" @change="carouselChange">
                    <el-carousel-item class="item" v-for="(item, index) in dataF7" :key="index">
                        <div class="left">
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.content }}</p>
                            <div class="btn-wrap">
                                <button @click="toFooterWrap">{{ $t('home.experienceNow') }}</button>
                            </div>
                        </div>
                        <img :src="item.img" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <div class="f6-box">
            <div class="box-title">
                <h1>{{ $t('home.f6_box_title') }}</h1>
                <h2 @click="toFooterWrap">{{ $t('home.f6_box_text') }}</h2>
            </div>
            <div class="f6-wrap container">
                <el-carousel :autoplay="true" ref="refF6" arrow="never" indicator-position="none">
                    <el-carousel-item class="item" v-for="(data, index) in dataF6" :key="index">
                        <div class="right" v-for="(item, index2) in data.list" :key="index2">
                            <img class="banner" :src="item.img" alt="">
                            <img class="logo" :src="item.logo" alt="">
                            <div class="content-box">
                              <div class="content">
                                <span>{{ item.name }}：</span>{{ item.content }}
                              </div>
                              <div class="content">
                                  {{ item.content2 }}
                              </div>
                            </div>
                            <div class="buttom" v-if="$currentLanguage != 'en'"
                              :style="{'padding': ($currentLanguage == 'en' ? '0 40px 5px' : '0 40px 20px')}">
                                <div>
                                    <img :src="item.icon" alt="">
                                    <div class="font padding"
                                        v-if="item.iconText == $t('home.dataF6_list1_obj1_iconText1') || 
                                        item.iconText == $t('home.dataF6_list1_obj2_iconText4') || 
                                        item.iconText == $t('home.dataF6_list1_obj3_iconText1')">
                                        {{ item.iconText }}</div>
                                    <div class="font" v-else>{{ item.iconText }}</div>
                                </div>
                                <div class="block"></div>
                                <div>
                                    <img :src="item.icon2" alt="">
                                    <div class="font">{{ item.iconText2 }}</div>
                                </div>
                                <div class="block"></div>
                                <div>
                                    <img :src="item.icon3" alt="">
                                    <div class="font font-size" v-if="item.iconText3 == $t('home.dataF6_list1_obj2_iconText4_3')">{{ item.iconText3 }}
                                    </div>
                                    <div class="font" v-else>{{ item.iconText3 }}</div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <div class="btn-wrap">
                    <button class="prev" @click="$refs['refF6'].prev()"></button>
                    <button class="next" @click="$refs['refF6'].next()"></button>
                </div>
            </div>
        </div>

        <div class="f8-box">
            <div class="box-title">
                <h1>{{ $t('home.f8_box_title') }}</h1>
                <h2>{{ $t('home.f8_box_text') }}</h2>
            </div>
            <div class="f8-wrap container">
                <div class="item" :class="[$currentLanguage == 'en'?'f8items':'']"
                    style="background: url('https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uwx56MpIqDm0B36LS2FXltnclhQiMoXNbfd9b0e0-cf35-423b-8458-44a02e9d0629.png'); background-size: 100% 100%;">
                    <h3>{{ $t('home.f8_wrap_h1') }}</h3>
                    <p v-if="$currentLanguage != 'en'">{{ $t('home.f8_wrap_p1') }}</p>
                </div>
                <div class="item" :class="[$currentLanguage == 'en'?'f8items':'']"
                    style="background: url('https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uZsZvWccipEmoR8htV8a1N8rl64l4qDV30112693-f322-4700-a31e-fb1048fbe8f2.png'); background-size: 100% 100%;">
                    <h3>{{ $t('home.f8_wrap_h2') }}</h3>
                    <p v-if="$currentLanguage != 'en'">{{ $t('home.f8_wrap_p2') }}</p>
                </div>
                <div class="item" :class="[$currentLanguage == 'en'?'f8items':'']"
                    style="background: url('https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u7z4e8B23Jj6fjD6H36WXJoP4wINZJKw6c7213b8-8a14-45a2-88e7-f0c57a9e1841.png'); background-size: 100% 100%;">
                    <h3>{{ $t('home.f8_wrap_h3') }}</h3>
                    <p v-if="$currentLanguage != 'en'">{{ $t('home.f8_wrap_p3') }}</p>
                </div>
                <div class="item" :class="[$currentLanguage == 'en'?'f8items':'']"
                    style="background: url('https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uPcDxyP8bYW1oxjPspHE7mDDhG9prnBU2ad9708b-e071-48b9-813e-32adf75b55b6.png'); background-size: 100% 100%;">
                    <h3>{{ $t('home.f8_wrap_h4') }}</h3>
                    <p v-if="$currentLanguage != 'en'">{{ $t('home.f8_wrap_p4') }}</p>
                </div>
                <div class="item" :class="[$currentLanguage == 'en'?'f8items':'']"
                    style="background: url('https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uHrMDFEvCBMKoUoZ9OPUR5vUd0wh9hNx0ef9cd3a-b825-422b-bd2f-4f37bd246b4b.png'); background-size: 100% 100%;">
                    <h3>{{ $t('home.f8_wrap_h5') }}</h3>
                    <p v-if="$currentLanguage != 'en'">{{ $t('home.f8_wrap_p5') }}</p>
                </div>
                <div class="item" :class="[$currentLanguage == 'en'?'f8items':'']"
                    style="background: url('https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uLnAc3mCPm6qiq9ifb3gEMJjG0F4OeIp199325c2-a4b8-460c-9f06-45a1fe34af39.png'); background-size: 100% 100%;">
                    <h3>{{ $t('home.f8_wrap_h6') }}</h3>
                    <p v-if="$currentLanguage != 'en'">{{ $t('home.f8_wrap_p6') }}</p>
                </div>
            </div>
        </div>

        <div class="f5-box">
            <div class="box-title">
                <h1>{{ $t('home.f5_box_title1') }}<span>{{ $t('home.f5_box_title2') }}</span></h1>
                <h2>{{ $t('home.f5_box_text') }}</h2>
            </div>
            <div class="f5-wrap container">
                <div class="item" v-for="(item, index) in dataF5" :key="index">
                    <div class="top">
                        <img :src="item.img" alt="">
                        <span>0{{ index + 1 }}</span>
                    </div>
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.content }}</p>
                    <button @click="toFooterWrap">{{ $t('home.learnMore') }}</button>
                </div>
            </div>
        </div>

        <!-- 趋势美业最新动态 - 文章 -->
        <div class="f9-box" v-if="articleData && articleData.length">
            <div class="box-title">
                <h1>{{ $t('home.f9_box_title') }}</h1>
            </div>
            <div class="f9-wrap container">
                <div v-for="(cateItem, cateIndex) in articleData" :key="cateIndex" class="item">
                    <div class="top" :style="cateItem.bgImgStyle" @click="toArticlList(cateItem)">
                        <div class="text">
                            <div class="english" v-if="$currentLanguage != 'en'">{{ cateItem.english }}</div>
                            <div style="display: flex">
                                <div class="title" :style="{'min-width': ($currentLanguage == 'en' ? '' : '75px')}">{{ cateItem.name }}</div>
                                <div>&nbsp;&nbsp;|&nbsp;&nbsp; </div>
                                <div class="label">{{ cateItem.label }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 文章列表 -->
                    <div class="bottom">
                        <div v-if="cateItem.articleList && cateItem.articleList.length">
                            <div v-for="(articleItem, articleIbdex) in cateItem.articleList" :key="articleIbdex">
                                <div class="articleItem" @click="toArticleDetails(articleItem)">
                                    <div class="createTime">{{ articleItem.createTime }}&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                    <div class="articleTitle">{{ articleItem.articleTitle }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="haveNotArticle">
                            {{ $t('home.noArticleList') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 回到顶部 -->
        <backTOP />

        <!-- 页脚 -->
        <div id="footer">
            <custom-footer />
        </div>

        <!-- 弹窗 -->
        <el-dialog
          title=""
          :visible.sync="dialogVisible"
          :before-close="handleClose"
          :show-close="false"
        >
          <div class="dialog-box">
            <div class="dialog-close">
              <img
                src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f1c20295-e944-4dc7-829d-b0ee5bdbc5cc.png"
                alt=""
                @click="dialogVisible = false"
              />
            </div>
            <div class="dialog-content">
              做好营销和销售，扩大你的生意就现在，立即获取增长方案！
            </div>
            <div class="dialog-btn">扫码咨询</div>
          </div>
        </el-dialog>
    </div>
</template>

<script>
import { getArticleCategoryPage, getArticlePage } from "@/api/api.js"

// 顶部导航栏组件
import commonNav from "@/components/commonNav/index.vue"
// 广告图组件
import customBanner from "@/components/customBanner/index.vue"
// 页脚
import customFooter from "@/components/customFooter/index.vue"

export default {
    name: "home",

    components: {
        commonNav,
        customBanner,
        customFooter,
    },

    data() {
        return {
            // 广告数据
            isCns: false,
            bannerData: [
                {
                  title: "",
                  banner: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1/material/5f8f65f0-8058-4408-8b48-089ed35b68bc.jpg",
                  subtitle: "",
                  content: [],
                  button: "",
                  openUrl: "https://docs.qq.com/form/page/DS2ZrcUhoSkR0R3pN",
                },//活动推广图
                {
                    title: "",
                    banner: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/188a3781-8bf7-4552-a8cb-edae55c6f64e.png',
                    subtitle: "",
                    content: [],
                    button: "",
                    openUrl: "",
                },
                {
                    title: this.$t('home.bannerData_title1'),
                    // banner: '__CDN__/assets/img/new_index/newCosmetology/banner.png',
                    banner: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/5dd55cb8-7b3f-4247-9eb4-e8ebaa965fd4.png',
                    subtitle: this.$t('home.bannerData_subtitle1'),
                    content: [this.$t('home.bannerData_content1_1'), this.$t('home.bannerData_content1_2')],
                    button: this.$t('home.bannerData_button1'),
                    openUrl: "",
                },
                {
                    title: this.$t('home.bannerData_title2'),
                    // banner: '__CDN__/assets/img/new_index/newCosmetology/banner.png',
                    banner: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f5a5e209-faa4-483b-9fea-72b1c393dc22.png',
                    subtitle: this.$t('home.bannerData_subtitle2'),
                    content: [],
                    button: this.$t('home.bannerData_button2'),
                    openUrl: "",
                },
                {
                  title: "",
                  banner: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/e1fecf95-a889-4523-9d0e-9f8234696180.jpg',
                  subtitle: "",
                  content: [],
                  button: "",
                  openUrl: "https://mp.weixin.qq.com/s/7sDBB_y_xb8kv5ymLfYTiQ",
                },
                {
                  title: "",
                  banner: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/6e68b9a2-da5c-4b47-b531-41b57fd56ade.jpg',
                  subtitle: "",
                  content: [],
                  button: "",
                  openUrl: "https://mp.weixin.qq.com/s/gC1PRwnOXdGA7faE1fOJSA",
                },
            ],

            // 四块卡片的数据
            dataF2: [{
                title: this.$t('home.dataF2_title1'),
                content: this.$t('home.dataF2_content1'),
                img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/b1448195-df82-4706-a0c9-bf18c1aefe06.png',
                imgAc: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/aa43e683-e929-4d74-b4c2-07f165442bcc.png',
            }, {
                title: this.$t('home.dataF2_title2'),
                content: this.$t('home.dataF2_content2'),
                img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/c1b41974-74b1-4a83-bb15-72ea0bafd193.png',
                imgAc: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/dc3b4b04-ba78-47c1-95f9-efa32b282bfb.png',
            }, {
                title: this.$t('home.dataF2_title3'),
                content: this.$t('home.dataF2_content3'),
                img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/e1b67aa8-4d47-4341-bca8-d577bd07e80f.png',
                imgAc: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/a3e2f804-d174-4a50-9743-28642ab79397.png',
            }, {
                title: this.$t('home.dataF2_title4'),
                content: this.$t('home.dataF2_content4'),
                img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/85232f0d-beb1-4ce9-998c-1dbbb0739221.png',
                imgAc: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/8da67a6f-3271-4221-a603-48eed5643396.png',
            }],

            currF4Idx: 0,
            dataF4: [{
                title: this.$t('home.dataF4_title1'),
                icon:'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/e18f68ba-f443-42ee-ad04-a118d186d8ac.png',
                desc: this.$t('home.dataF4_content1'),
                img: this.$currentLanguage == 'en'? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/d66ef01b-9474-4dac-b515-ef645a6c5b25.png' :
                this.$currentLanguage == 'zh-hk' ? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/3da0ad3e-6047-4235-a3d7-edd9379ad713.png' :
                'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/5ba52471-880e-4927-ada9-d30aa6a7739e.png',
            }, {
                title: this.$t('home.dataF4_title6'),
                icon:'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/9329e849-5e82-41e5-b0f3-b2ff9d285f1d.png',
                desc: this.$t('home.dataF4_content6'),
                img: this.$currentLanguage == 'en'? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/48f57e2c-8cbc-4a66-838e-2621f69d674d.png' :
                this.$currentLanguage == 'zh-hk' ? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/06188cc2-2774-4a1e-84a5-fe30af9fcb54.png' :
                'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u1D0sUldzyjSWgsdBbm7Pje8FIfhi2rQ1761e41b-27ab-4c17-8170-75a8416bbb13.png',
            }, {
                title: this.$t('home.dataF4_title11'),
                icon:'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/b17fec50-7123-4256-921f-5b399ba58208.png',
                desc: this.$t('home.dataF4_content11'),
                img: this.$currentLanguage == 'en'? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/e5bf26c6-7bdb-4b5d-86e8-907319a9e2f8.png' :
                this.$currentLanguage == 'zh-hk' ? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f0c7d56c-cf07-48a6-ab75-9db23da597f2.png' :
                'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/4a8292cc-4851-451f-994a-da56086a9a9c.png',
            }, {
                title: this.$t('home.dataF4_title16'),
                icon:'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/dbb8b810-9341-452c-aedf-7119906f975c.png',
                desc: this.$t('home.dataF4_content16'),
                img: this.$currentLanguage == 'en'? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/98137809-ddcf-4368-9173-f8d9fbe224a6.png' :
                this.$currentLanguage == 'zh-hk' ? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/376fe5b1-89be-403c-a7b1-55bf1274fd09.png' :
                'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/3d4e60f7-92c9-401f-96ab-a151701a13bc.png',
            }, {
                title: this.$t('home.dataF4_title21'),
                icon:'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/ad3df425-0852-48a7-8764-77324ec417ae.png',
                desc: this.$t('home.dataF4_content21'),
                img: this.$currentLanguage == 'en'? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/0cae89fb-cffe-4f75-935a-07067ba9d6df.png' :
                this.$currentLanguage == 'zh-hk' ? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/dce09160-86d6-4d3b-a3d8-e9da929f04a4.png' :
                'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/89c63653-5c7c-4364-a619-1dbf390558b5.png',
            }, {
                title: this.$t('home.dataF4_title26'),
                icon:'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/78052e44-356b-4d14-9619-810c6caa0365.png',
                desc: this.$t('home.dataF4_content26'),
                img: this.$currentLanguage == 'en'? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/453e89e2-6389-4a8a-91c1-61eb7baf4238.png' :
                this.$currentLanguage == 'zh-hk' ? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/afb5441b-5e9b-4ce5-a71b-aaa40414acfb.png' :
                'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/b5fbe8ac-5162-4384-acd4-39305c1565fa.png',
            }, {
                title: this.$t('home.dataF4_title31'),
                icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/2a339a3b-1a1f-4cd5-8f17-de00e588c77c.png',
                desc: this.$t('home.dataF4_content31'),
                img: this.$currentLanguage == 'en'? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/308e112d-1b48-4cd4-9057-90c8d9b59a65.png' :
                this.$currentLanguage == 'zh-hk' ? 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/5bc93187-1d91-428c-9d18-fafa634759ea.png' :
                'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/50d5e2fb-dc00-47f9-8a75-d23330e45f72.png',
            }],

            dataF5: [{
                title: this.$t('home.dataF5_title1'),
                content: this.$t('home.dataF5_content1'),
                img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/2df4b363-56a1-4174-9f68-b9e71881f241.png'
            }, {
                title: this.$t('home.dataF5_title2'),
                content: this.$t('home.dataF5_content2'),
                img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/3b418cc4-4142-49ca-b3bd-1766576644fc.png'
            }, {
                title: this.$t('home.dataF5_title3'),
                content: this.$t('home.dataF5_content3'),
                img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/ae0a3576-ba57-452d-a9c4-f368f067cf60.png'
            }, {
                title: this.$t('home.dataF5_title4'),
                content: this.$t('home.dataF5_content4'),
                img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uPT8y4RZotHVKlcSsBqCU6VuP4vRWBIJba3ea83c-a24f-465a-8c4f-efbf4ac33e0c.png'
            }],

            dataF6: [{
                list: [
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u1v4937hVEySPiDVHnoyrMUE5g9dGaBRaae3ec75-296a-49d5-830e-670adbf3cca4.png',
                        name: this.$t('home.dataF6_list1_obj1_name1'),
                        content: this.$t('home.dataF6_list1_obj1_content1'),
                        content2: this.$t('home.dataF6_list1_obj1_content1_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uYgpVrQRSDG7CAn1yZDJMCK3sWMmvLXU22b0737f-a749-445b-baee-63a5cff76aa1.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u5ztPO3a8rJPVYjqI6xirWuz6OUAHCYtf71adc30-7f39-47c4-ae80-6c1bc0078234.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/8bc8c043-8963-45d8-95d2-910305317f17.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uGOtn7QEkXDZyNk5QLj22BHBF9yxJucO79162ee7-81f2-41e8-9287-f97c1fd67c57.png',
                        iconText: this.$t('home.dataF6_list1_obj1_iconText1'),
                        iconText2: this.$t('home.dataF6_list1_obj1_iconText1_2'),
                        iconText3: this.$t('home.dataF6_list1_obj1_iconText1_3'),
                    },
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uLtuWfQBAJL5DUa87pQYO5VMjQ27T4tkd46a58e2-b4e7-47af-8616-369e8007260a.png',
                        name: this.$t('home.dataF6_list1_obj1_name2'),
                        content: this.$t('home.dataF6_list1_obj1_content2'),
                        content2: this.$t('home.dataF6_list1_obj1_content2_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/9924c08a-537c-4cb9-81a3-3a6e451ee48b.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/3b300461-cf24-4d28-a35b-353b98e8abcb.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u6ej5gEC9egnRlbW3b1wXHir6N3kOnoB86b4a519-e9dc-4f87-8e92-693c39a1e0cf.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uSWNxWnZgXb7plt1kLlq1QuddCbVD3iFf1a19252-7aa7-40da-a43c-910957baa452.png',
                        iconText: this.$t('home.dataF6_list1_obj1_iconText2'),
                        iconText2: this.$t('home.dataF6_list1_obj1_iconText2_2'),
                        iconText3: this.$t('home.dataF6_list1_obj1_iconText2_3'),
                    },
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uCSJRm257W8P7yqqob6Kv9iGVrnDNNMP145d515c-1d4a-496a-85cf-6fa22f425d12.png',
                        name: this.$t('home.dataF6_list1_obj1_name3'),
                        content: this.$t('home.dataF6_list1_obj1_content3'),
                        content2: this.$t('home.dataF6_list1_obj1_content3_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/ur4JrrjsDk06vHDVPtLfYBIksAk2XPtvb77ff8cf-1f2e-4d59-9fbf-5e3dd9b3b190.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uIZKChqKPGMVoNR7v9plOkA0ma7Gmp1Kc44edc65-7b52-486b-9ed7-1659d7866c66.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/686272e6-8bc2-45a1-b047-a122cce32084.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uYi3a0Ackum4YlBJnyPPaSLokYebv75La22bb7f3-b7f5-4243-b701-c5ff65e06c40.png',
                        iconText: this.$t('home.dataF6_list1_obj1_iconText3'),
                        iconText2: this.$t('home.dataF6_list1_obj1_iconText3_2'),
                        iconText3: this.$t('home.dataF6_list1_obj1_iconText3_3'),
                    },
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u1IkdWcLiA0N1lGm32qYBVNlWnQs6rP37d6fe5e1-6e93-49e3-a529-60919f0a2925.png',
                        name: this.$t('home.dataF6_list1_obj1_name4'),
                        content: this.$t('home.dataF6_list1_obj1_content4'),
                        content2: this.$t('home.dataF6_list1_obj1_content4_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uQC3Gsj6FiakUVXMRmjf14J88FqgmAZo2172a216-01d7-43c8-9e98-30ad8d40c082.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/utJj4vpSGiuu7Z55biclvDfvctxLIvSn9cd018a5-2c98-4790-b489-067caabe0ed1.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uh21OwHyCTDOmyC7JwLcSpOrLxMCUTVMf44af11e-02fb-4d3e-bd25-dbd23187ed67.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u2lmCVoZoZgyEM6vzR8DdmM5bzhlRUuB5066b523-5066-46f8-bf83-8624b2710e98.png',
                        iconText: this.$t('home.dataF6_list1_obj1_iconText4'),
                        iconText2: this.$t('home.dataF6_list1_obj1_iconText4_2'),
                        iconText3: this.$t('home.dataF6_list1_obj1_iconText4_3'),
                    }
                ]
            }, {
                list: [
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uysIvJCDywhhXsH6FBHPMbGeUf4ZLBhM3f58041c-e92c-4f57-ab6d-e5826f5d6272.png',
                        name: this.$t('home.dataF6_list1_obj2_name1'),
                        content: this.$t('home.dataF6_list1_obj2_content1'),
                        content2: this.$t('home.dataF6_list1_obj2_content1_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uAfaAUWtCHvMUJnrZjtLqQ3M9GpUxjKueb9345fb-74da-4825-83df-147c257c0f95.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/c139bb39-6dcb-4c94-88eb-f64bb679c924.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/ujsM6yGdK9XymyBMTtDTNsEErG4OOkAB885d452a-45e1-4435-8bf6-334389acae14.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uQPvC4NILM0QSckICHC5r7gWFsUOcW5R45849e53-8768-41cc-8464-31aae15bc199.png',
                        iconText: this.$t('home.dataF6_list1_obj2_iconText1'),
                        iconText2: this.$t('home.dataF6_list1_obj2_iconText1_2'),
                        iconText3: this.$t('home.dataF6_list1_obj2_iconText1_3'),
                    },
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/unYkLsuMQDPPGO9HNOwuJpGoGWmiJ8Moabc9ffce-5686-4d1e-b852-418b9e7174f6.png',
                        name: this.$t('home.dataF6_list1_obj2_name2'),
                        content: this.$t('home.dataF6_list1_obj2_content2'),
                        content2: this.$t('home.dataF6_list1_obj2_content2_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/ulIFK9AQdwQR0lJseFXDnh5nKZPyKp3A615ba3ed-5e9b-46c9-97fc-e65c05352996.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/ufM7YpKckkGR9u5RHmt4AFxy3gj9SaFg3b7e7158-7d16-4507-8d38-4a932e03efc6.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uOiBunV0tPeqralbj18i8s1S2Ds7l4Mh161758d5-a9a9-4910-8369-c89a1cb643ad.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u4chxPBYGizd8n3X2R4fjjaaVVeI4BEQaeab9eed-132e-4784-b4df-0e48b53553fd.png',
                        iconText: this.$t('home.dataF6_list1_obj2_iconText2'),
                        iconText2: this.$t('home.dataF6_list1_obj2_iconText2_2'),
                        iconText3: this.$t('home.dataF6_list1_obj2_iconText2_3'),
                    },
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uOpDNIQoGRUMML2w5jmDWUnkWJvbJKHjd47bd6c3-4519-456f-bf4f-1a6efa287b39.png',
                        name: this.$t('home.dataF6_list1_obj2_name3'),
                        content: this.$t('home.dataF6_list1_obj2_content3'),
                        content2: this.$t('home.dataF6_list1_obj2_content3_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uLLahBZaeccl1lkQfe88MFubThhNyPqbc5ef34d9-09df-43d0-bca7-7f49bf3cfabe.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uRjxX5tfe2hxMs1Y0WfwJSrVFkaauDjwa3982aef-3a95-4420-a153-09c65e42d860.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uKYVn9wZObsuAGfGkOp0bpP8hDQuaFJBd49aadd8-ba85-463a-a70b-750b28152da1.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uqLPU26W54BqU3rgZpcgzv8BLPCWm4Z4293cb583-7e2c-474f-92d8-95d349845bcb.png',
                        iconText: this.$t('home.dataF6_list1_obj2_iconText3'),
                        iconText2: this.$t('home.dataF6_list1_obj2_iconText3_2'),
                        iconText3: this.$t('home.dataF6_list1_obj2_iconText3_3'),
                    },
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u8azuGs4AfGonGBipOJ8m8mo9velzfum83c3a107-d340-44f8-956d-49d5cdcb1791.png',
                        name: this.$t('home.dataF6_list1_obj2_name4'),
                        content: this.$t('home.dataF6_list1_obj2_content4'),
                        content2: this.$t('home.dataF6_list1_obj2_content4_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uVkVRsH4ikdJIyIRnDBlbWfSTyhfvQZ1551a40c0-b278-46c2-8b57-7b2fc573cbb4.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uk5252B1xDyOC1U1USxRlgAew9k9RuMe52b5a6f5-98b6-4cf8-b120-263e26748e02.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uaDnWX84QivHCWI3vGfTxfflvKYS1WSA771c2688-0363-4941-b9e2-485e40d69568.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/upM7vhTmeOhw0YyHinlQCFIldpA0nsfO1bb8045e-fbe9-4ece-a849-4b866940819d.png',
                        iconText: this.$t('home.dataF6_list1_obj2_iconText4'),
                        iconText2: this.$t('home.dataF6_list1_obj2_iconText4_2'),
                        iconText3: this.$t('home.dataF6_list1_obj2_iconText4_3'),
                    }
                ]
            }, {
                list: [
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uyS72XjagM8bCjH7pgBcc1t12zVMTzDZaf4f1c41-21c7-42ed-8dd9-8e70eccea9dd.png',
                        name: this.$t('home.dataF6_list1_obj3_name1'),
                        content: this.$t('home.dataF6_list1_obj3_content1'),
                        content2: this.$t('home.dataF6_list1_obj3_content1_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uhiaTQUTujZt3Kxfoq242vHDDhmbbf0De8da0f80-2433-4c8b-b82f-073488a7bb46.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u6Jpwc6wIdNw57GSBIbqTKuMgPh7vhrb8c5c97c0-fa0d-4005-b68e-93cae75fe8bb.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/utscTXX5S6dLEitnOlOUrB5YEpdwtkf60e48cad0-8f64-482a-ba86-6d5be74f7f23.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u3VB1EqX02zIznWdOFwbRJgRfIzZkeUqf112445c-d0d9-42a4-bd4c-93d74011ca4e.png',
                        iconText: this.$t('home.dataF6_list1_obj3_iconText1'),
                        iconText2: this.$t('home.dataF6_list1_obj3_iconText1_2'),
                        iconText3: this.$t('home.dataF6_list1_obj3_iconText1_3'),
                    },
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/5603d439-fde5-4b77-bc99-a7733e79e92c.png',
                        name: this.$t('home.dataF7_list1_obj1_name1'),
                        content: this.$t('home.dataF7_list1_obj1_content1'),
                        content2: this.$t('home.dataF7_list1_obj1_content1_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/5c19d4df-7f6a-4fc5-b629-95ec230569fd.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/30e37136-c046-4ed4-8352-1357d88864bc.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/bee40baa-74a4-48e6-8bb4-bc5d9c33afd9.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/01eb76e4-ca3e-4866-870a-715a21032311.png',
                        iconText: this.$t('home.dataF7_list1_obj1_iconText1'),
                        iconText2: this.$t('home.dataF7_list1_obj1_iconText1_2'),
                        iconText3: this.$t('home.dataF7_list1_obj1_iconText1_3'),
                    },
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/c8f21fdc-acaa-4506-a4dd-6c34b3673429.png',
                        name: this.$t('home.dataF6_list1_obj3_name3'),
                        content: this.$t('home.dataF6_list1_obj3_content3'),
                        content2: this.$t('home.dataF6_list1_obj3_content3_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/5f517b9c-6538-4600-a47c-f2f8bc482c80.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/6dfd3e47-e3cb-4410-983e-a22cfa7b458a.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/eb573c37-5890-495e-9ef1-972c1c002bab.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/e7a70396-c9fc-4336-a322-97641e4bdd4f.png',
                        iconText: this.$t('home.dataF6_list1_obj3_iconText3'),
                        iconText2: this.$t('home.dataF6_list1_obj3_iconText3_2'),
                        iconText3: this.$t('home.dataF6_list1_obj3_iconText3_3'),
                    },
                    {
                        logo: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u1v4937hVEySPiDVHnoyrMUE5g9dGaBRaae3ec75-296a-49d5-830e-670adbf3cca4.png',
                        name: this.$t('home.dataF6_list1_obj1_name1'),
                        content: this.$t('home.dataF6_list1_obj1_content1'),
                        content2: this.$t('home.dataF6_list1_obj1_content1_2'),
                        img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uYgpVrQRSDG7CAn1yZDJMCK3sWMmvLXU22b0737f-a749-445b-baee-63a5cff76aa1.png',
                        icon: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/u5ztPO3a8rJPVYjqI6xirWuz6OUAHCYtf71adc30-7f39-47c4-ae80-6c1bc0078234.png',
                        icon2: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/8bc8c043-8963-45d8-95d2-910305317f17.png',
                        icon3: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uGOtn7QEkXDZyNk5QLj22BHBF9yxJucO79162ee7-81f2-41e8-9287-f97c1fd67c57.png',
                        iconText: this.$t('home.dataF6_list1_obj1_iconText1'),
                        iconText2: this.$t('home.dataF6_list1_obj1_iconText1_2'),
                        iconText3: this.$t('home.dataF6_list1_obj1_iconText1_3'),
                    },
                ]
            }],

            currF7Idx: 0,
            dataF7: [{
                title: this.$t('home.dataF7_title1'),
                content: this.$t('home.dataF7_content1'),
                // img: '__CDN__/assets/img/new_index/newCosmetology/f7_img_3.png'
                img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/usAVAkdarjJto5RkKEvOI7L8JVls1lBbb56fc7d4-4291-4f35-9dd1-064154dd162f.png'
            }, {
                title: this.$t('home.dataF7_title2'),
                content: this.$t('home.dataF7_content2'),
                img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uZWSOS2wWgel8BfWB2EqNC68dsvVj5JC7a7e7900-a429-48c3-b30c-ead8be652693.png'
            }, {
                title: this.$t('home.dataF7_title3'),
                content: this.$t('home.dataF7_content3'),
                img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/1531154512344256512/uUG4CMZAsQTR09VULSnESIffMtgl1fyx8e1f3ace-3ffe-41ee-994a-c9bde8693236.png'
            }],

            // 文章分类-内容数据
            articleData: [],

            dialogVisible: false,
        }
    },

    mounted() {
        this.getArticleCategoryPage(); //获取文章分类列表
    },

    methods: {
        /**
         * 获取文章分类列表
         */
        getArticleCategoryPage() {
            getArticleCategoryPage({
                current: 1,
                size: 3, //只能前面3条数据
            }).then(res => {
                // console.log("获取文章分类列表", res)
                let articleCate = res.data.records; //文章分类
                if (articleCate && articleCate.length) {
                    if (articleCate[0]) {
                        articleCate[0].english = "Product dynamics";
                        articleCate[0].name = this.$t('home.articleCate_name1');
                        articleCate[0].label = this.$t('home.articleCate_label1');
                        articleCate[0].bgImgStyle = "background: url('https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/a91c58a5-5eb8-4c3f-b31e-3f97ecbfa332.png'); background-size: 100% 100%;";
                        articleCate[0].articleList = [];
                        this.getArticlePage(articleCate[0], articleCate[0].id);
                    };
                    if (articleCate[1]) {
                        articleCate[1].english = "Signing dynamics";
                        articleCate[1].name = this.$t('home.articleCate_name2');
                        articleCate[1].label = this.$t('home.articleCate_label2');
                        articleCate[1].bgImgStyle = "background: url('https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/6ab2a353-4057-4ecd-ba5f-07af52f5bbb2.png'); background-size: 100% 100%;";
                        articleCate[1].articleList = [];
                        this.getArticlePage(articleCate[1], articleCate[1].id);
                    };
                    if (articleCate[2]) {
                        articleCate[2].english = "Customer stories";
                        articleCate[2].name = this.$t('home.articleCate_name3');
                        articleCate[2].label = this.$t('home.articleCate_label3');
                        articleCate[2].bgImgStyle = "background: url('https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/44a953cf-cbc2-4a8a-9764-131e1ceb647c.png'); background-size: 100% 100%;";
                        articleCate[2].articleList = [];
                        this.getArticlePage(articleCate[2], articleCate[2].id);

                    }
                };
                this.articleData = articleCate;
            })
        },

        // 获取文章列表
        getArticlePage(item, categoryId) {
            getArticlePage({
                current: 1,
                size: 3,
                categoryId
            }).then(res => {
                // console.log("文章列表", res)
                let articleList = res.data.records; //文章列表
                item.articleList = articleList;
            })
        },

        // 跳转文章详情
        toArticleDetails(item) {
            if (item.originalUrl) {
                // window.open(item.originalUrl, "_self")
                window.open(item.originalUrl)
            }else{
              this.$router.push({
                path: '/articlDetail',
                query: {
                  id: item.id,
                  categoryId: item.categoryId||'',
                }
              })
            }
        },


        // 免费咨询
        handleConsult() {
            if (!this.isCns) {
                window.open(
                    'https://tb.53kf.com/code/client/a35a0e3e8c85a6d8df443eec771d6eb10/1')
            } else {
                window.open(
                    'http://p.qiao.baidu.com/cps/chat?siteId=14912370&userId=30331076&siteToken=798cbd327d4884415737486bfe7fe0b6'
                )
            }
        },

        // 幻灯片切换
        carouselChange(e) {
            // console.log('幻灯片切换',e);
            this.currF7Idx = e;
        },

        /**
         *  跳转到文章列表
         *  @param cateItem 文章分类对象
         */
        toArticlList(cateItem) {
            this.$router.push(
                {
                    path: '/articlList',
                    query: {
                        cateId: cateItem.id,
                        cateName: cateItem.name,
                    },
                }
            );
        },

        /**
         * 锚点定位
         */
        toFooterWrap() {
            document.querySelector('#footer').scrollIntoView({
				behavior: "smooth"
			});
        },



        /**
         * 关闭弹窗
         */
        handleClose() {
          this.dialogVisible = false;
        },
    }
}
</script>

<style scoped>
@import url('@/style/base.css');
</style>

<style lang="less" scoped>
// 首页样式
.f8items{
  justify-content: space-around;
}
body {
    min-width: auto;
    font-family: Microsoft YaHei;
    // overflow-x: hidden;
  }
  
  button {
    display: block;
    cursor: pointer;
  }
  
  a {
    color: #333;
  }
  
  img,
  span {
    display: block;
  }
  
  .box-title {
    min-width: auto;
    margin: 4.1666vw 0 1.8229vw;
  
    h1 {
      font-size: 30px;
      font-weight: bold;
    }
  
    h2 {
      // font-size: 18/1932vw;
      font-size: 18px;
      font-weight: 400;
      color: #666;
      // margin-top: 15px;
      margin-top: 0px;
    }
  }
  
  .container {
    width: 62.5vw;
    padding-top: 0;
  }
  
  .custom-banner_box {
    .container_box {
      width: 1200px;
    }
  
    .banner_wrap {
      >h1 {
        line-height: 55px;
        font-size: 55px;
        font-weight: bold;
      }
  
      >h2 {
        width: 650px;
        line-height: 26px;
        font-size: 26px;
        font-weight: 400;
        margin: 34px 0 31px;
      }
  
      >p {
        width: 372px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
      }
  
      >button {
        width: 214px;
        height: 61px;
        background: #04CB94 !important;
        border-radius: 4px !important;
        font-size: 20px;
        font-weight: bold;
        color: #FEFEFE;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px !important;
      }
    }
  }
  
  .f2-box {
    background: #F9FAFC;
  
    .f2-wrap {
    //   transform: translateY(-33px);
    transform: translateY(-33px);
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 2;
  
      .item {
        width: 280px;
        height: 130px;
        // width: 280px;
        // height: 130px;
        position: relative;
  
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
  
        .item-bg {
          display: block;
        }
  
        .item-bg-ac {
          display: none;
        }
  
        .content {
          position: relative;
          z-index: 10;
        //   padding-left: 105px;
          padding-left: 105px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        //   height: 130px;
          height: 130px;
  
          h3 {
            font-size: 22px;
            font-weight: bold;
            color: #252931;
          }
  
          span {
            font-size: 14px;
            font-weight: 300;
            color: #85878A;
          }
        }
  
        &:hover {
          .item-bg {
            display: none;
          }
  
          .item-bg-ac {
            display: block;
          }
  
          .content {
  
            h3 {
              color: #FFFFFF;
            }
  
            span {
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
  
  .f3-box {
    background: #F9FAFC;
    overflow: hidden;
    h1 {
      margin-bottom: 17px;
    }
  
    img {
    //   width: 1049px;
    //   margin: 72px auto 100px;
      width: 1049px;
      margin: 72px auto 100px;
    }
  }
  
  .f4-box {
    overflow: hidden;
    // padding-bottom: 93px;
    padding-bottom: 93px;

    h1 {
      margin-bottom: 14px;
    }
  
    .f4-wrap {
  
      .tab-box {
        display: flex;
        justify-content: space-around;
        // margin-bottom: 40px;
        margin-bottom: 40px;
  
        .tab-item {
        //   width: 138px;
        //   height: 40px;
        //   border-radius: 4px;
        //   font-size: 18px;
          width: 138px;
          height: 40px;
          border-radius: 4px;
          font-size: 18px;
          font-weight: 400;
          color: #85878A;
          display: flex;
          justify-content: center;
          // align-items: center;
          cursor: pointer;
          transition: all .3s linear;
  
          &.tab-active {
            // width: 214px;
            width: 214px;
            color: #2EDFAB;
            border-bottom: 3px solid #2EDFAB;
          }
        }
      }
  
      .content-box {
        // background: #2EDFAB;
        // box-shadow: 0px 5px 20px 0px rgba(14, 112, 253, 0.2000);
        // border-radius: 4px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
  
        .img-box {
          // position: relative;
          // display: flex;
          // align-items: flex-end;
          // margin-left: 60px;
        //   margin-left: 50px;
          margin-left: 50px;
          // background: #2EDFAB;
          
          img {
            // height: 389px;
            // margin-top: 76px;
            // margin-top: 30px;
            // width: 740px;
            // height: 560px;
            margin-top: 30px;
            width: 740px;
            height: 560px;
          }
  
          .img-btn {
            position: relative;
            z-index: 999;
            // bottom: 0;
            margin: 0 auto;
            // margin-top: -81px;
            margin-top: -81px;
            text-align: center;
            font-size: 24px;
            font-weight: 300;
            color: #FFFFFF;
            width: 218px;
            height: 64px;
            line-height: 64px;
            background: #04CB94;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,0.22);
            border-radius: 32px;
          }
        }
  
        .left {
          padding: 49px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
  
          .content-wrap {
            // padding-left: 70px;
  
            .item {
              width: 410px;
              padding: 21px 15px 30px;
              position: relative;
  
              h3 {
                font-size: 18px;
                font-weight: bold;
                color: #000000;
              }
  
              p {
                font-size: 14px;
                color: #666666;
              }
              
              .item-one {
                font-size: 36px;
              }
              
              .item-two {
                color: #000000;
                font-weight: bold;
                font-size: 24px;
              }
              
              .item-banner {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                // background: #167cff;
                
                .icon-img {
                  margin-right: 9px;
                  width: 34px;
                  height: 34px;
                }
              }
  
              &::before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                background: #FFFFFF;
                border-radius: 50%;
                position: absolute;
                top: 6px;
                left: -20px;
              }
            }
            
            .item-background {
              // width: 410px;
              // padding: 21px 15px 30px;
              background: #F8FAFC;
              border-radius: 10px;
            }
            
          }
        }
      }
    }
  }
  
  .f5-box {
    overflow: hidden;
    background: #FFFFFF;
    padding-bottom: 80px;
  
    .box-title {
      h1 {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 14px;
  
        span {
          display: inline-block;
          font-size: 40px;
        }
      }
    }
  
    .f5-wrap {
      display: flex;
      justify-content: space-between;
  
      .item {
        width: 264px;
        min-height: 400px;
        background: #FFFFFF;
        // box-shadow: 0px 5px 20px 0px rgba(14, 112, 253, 0.2000);
        // border-radius: 4px;
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 15px;
        transition: all .3s linear;
  
        .top {
          display: flex;
  
          img {
            width: 79px;
            height: 79px;
            margin-top: 26px;
            transition: all .3s linear;
          }
  
          span {
            font-size: 50px;
            font-weight: bold;
            color: #fff;
            margin-top: 30px;
            transform: scale(0);
            transition: all .3s linear;
          }
        }
  
        >h3 {
          font-size: 18px;
          font-weight: bold;
          color: #252931;
          margin: 9px 0 29px;
          transition: all .3s linear;
        }
  
        p {
          flex: 1 1 auto;
          font-size: 14px;
          font-weight: 400;
          color: #85878A;
          transition: all .3s linear;
        }
  
        button {
          width: 110px;
          height: 30px;
          border: 1px solid #CCCCCC;
          border-radius: 15px;
          margin: 48px 0;
          // margin-top: 40px;
          // margin-bottom: 48px 0;
          font-size: 16px;
          font-weight: bold;
          color: #666666;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all .3s linear;
          background: #FFFFFF;
        }
  
        &:hover {
          background: #14D6A3;
          box-shadow: 0px 5px 20px 0px rgba(14, 112, 253, 0.2000);
  
          .top {
            img {
              width: 0;
              height: 0;
            }
  
            span {
              transform: scale(1);
            }
          }
  
          >h3 {
            color: #fff;
          }
  
          p {
            color: #fff;
          }
  
          button {
            border: 1px solid #fff;
            background: #FFFFFF;
          }
        }
      }
    }
  }
  
  
  .f6-box {
    overflow: hidden;
    background: #ffffff;
  
    .box-title {
      h1 {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        letter-spacing: 3px;
  
        span {
          display: inline-block;
          font-size: 40px;
        }
      }
      
      h2 {
        margin-top: 29px;
        font-size: 18px;
        font-weight: 400;
        color: #0071EF;
      }
    }
  
    .f6-wrap {
       // width: 72.5vw;
       width: 88.5vw;
       
      .el-carousel{
        // box-shadow: 0 5px 20px 0 rgb(14 112 253/20%);
        // box-shadow: 0 5px 20px 0 rgba(14,112,253,0.2000);
      }
      /deep/ .el-carousel__container {
        // height: 418px;
        height: 650px;
      }
  
      .item {
        // padding: 50px;
        display: flex;
        justify-content: space-between;
  
        .right {
          position: relative;
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          margin-right: 30px;
          width: 382px;
          height: 600px;
          box-shadow: 0px 0px 16px 0px rgba(8,8,8,0.18);
          border-radius: 15px;
          // background: #e6e6e6;
  
          .banner {
            // margin-top: 19px;
            // width: 278px;
            width: 100%;
          }
          
          .logo {
            margin: 14px 24px 0;
            width: 104px;
            // height: 52px;
          }

          .content-box {
            height: 150px;
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          
          .content {
            // display: flex;
            // background: #adccfa;
            width: 334px;
            margin: 0 24px;
            margin-top: 19px;
            font-size: 12px;
            font-weight: bold;
            color: #666666;
            // 支持换行
            white-space: normal;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            // 支持换行结束
            
            
            span {
              display: inline-block;
              color: #000000;
            }
          }
          
          .buttom {
            // position: absolute;
            // bottom: 41px;
            margin-top: 19px;
            padding: 0 0px 20px;
            display: flex;
            
            img {
              width: 74px;
              height: 70px;
            }
            
            .block {
              margin-top: 19px;
              margin-left: 12px;
              margin-right: 25px;
              width: 1px;
              height: 41px;
              background: #75e6c8;
            }
            
            .font {
              width: 85px;
              margin-top: 14px;
              // padding-left: 5px;
              font-size: 16px;
              font-weight: 400;
              color: #75e6c8;
              // 支持换行
              white-space: normal;
              word-wrap: break-word;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              // 支持换行结束
            }
            
            .padding {
              padding-left: 7px;
            }
            
            .font-size {
              margin-left: -10px;
              font-size: 16px;
            }
          }
  
          h3 {
            font-size: 18px;
            font-weight: bold;
            color: #252931;
            margin: 35px 0 15px;
          }
  
          .func-wrap {
            font-size: 14px;
            font-weight: 400;
            color: #85878A;
            display: flex;
            flex-wrap: wrap;
  
            span:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }
  
      >.btn-wrap {
        display: flex;
        justify-content: center;
        margin: 60px auto 70px;
  
        button {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          // border: 1rpx solid #cdcdcd;
          // border-radius: 6px;
          // background-color: #016AFA;
          background-color: #f5f5f5;
          opacity: .5;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all .3s linear;
  
          &.prev {
            margin-right: 40px;
          }
  
          &.prev::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-top: 3px solid #b4b4b4;
            border-left: 3px solid #b4b4b4;
            transform: translateX(2px) rotateZ(-45deg);
          }
  
          &.next::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-top: 3px solid #b4b4b4;
            border-right: 3px solid #b4b4b4;
            transform: translateX(-2px) rotateZ(45deg);
          }
  
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  
  .f7-box {
    background: #F9FAFC;
    overflow: hidden;

    h1 {
      margin-bottom: 14px;
    }
  
    .f7-wrap {
      
      .tab-box {
        display: flex;
        justify-content: space-around;
        margin-bottom: 40px;
        border-bottom: 2px solid #dbdcdd;
        
        .tab-item {
          width: 214px;
          height: 40px;
          border-radius: 4px;
          font-size: 18px;
          font-weight: 400;
          color: #85878A;
          display: flex;
          justify-content: center;
          // align-items: center;
          cursor: pointer;
          transition: all .3s linear;
      
          &.tab-active {
            width: 214px;
            color: #2EDFAB;
            border-bottom: 3px solid #2EDFAB;
          }
        }
      }
      
      /deep/ .el-carousel__container {
        height: 381px;
      }
  
      .item {
        padding: 50px;
        display: flex;
        justify-content: space-between;
  
        .left {
          width: 400px;
  
          h3 {
            font-size: 20px;
            font-weight: bold;
            color: #252931;
            margin: 26px 0 35px;
          }
  
          p {
            flex: 1;
            font-size: 14px;
            font-weight: 400;
            color: #85878A;
          }
  
          .btn-wrap {
            margin-top: 40px;
  
            button {
              background: #04CB94;
              width: 164px;
              height: 44px;
              border-radius: 4px;
              font-size: 16px;
              font-weight: 400;
              font-weight: bold;
              color: #FFFFFF;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
  
        >img {
          margin-left: 50px;
          height: 100%;
        }
      }
  
      >.btn-wrap {
        display: flex;
        justify-content: center;
        margin: 60px auto 70px;
  
        button {
          width: 46px;
          height: 46px;
          border-radius: 6px;
          background-color: #016AFA;
          opacity: .5;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all .3s linear;
  
          &.prev {
            margin-right: 40px;
          }
  
          &.prev::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-top: 2px solid #fff;
            border-left: 2px solid #fff;
            transform: translateX(2px) rotateZ(-45deg);
          }
  
          &.next::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: translateX(-2px) rotateZ(45deg);
          }
  
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  
  .f8-box {
    overflow: hidden;
    padding-bottom: 70px;
    background: #F9FAFC;
    h1 {
      margin-bottom: 15px;
    }
  
    .f8-wrap {
      display: grid;
      justify-content: center;
      gap: 24px 21px;
      grid-template-columns: repeat(3, 282px);
      grid-template-rows: repeat(2, 208px);
  
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 51px 47px;
        color: #FFFFFF;
  
        button {
          width: 46px;
          height: 46px;
          border: 3px solid #FFFFFF;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;
  
          &::before {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            border-top: 3px solid #fff;
            border-right: 3px solid #fff;
            transform: translateX(-3px) rotateZ(45deg);
          }
        }
  
        h3 {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 12px;
        }
  
        p {
          font-size: 14px;
          font-weight: 400;
          text-align: center;
        }
  
        &.link {
          grid-column-start: 4;
          grid-column-end: 5;
          grid-row-start: 1;
          grid-row-end: 3;
          justify-content: center;
        }
      }
    }
  }
  
  // 趋势美业最新动态 - 文章
  .f9-box {
    overflow: hidden;
    padding-bottom: 70px;
    .f9-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // 文章分类
      .item {
        width: 388.7px;
        height: 324.4px;
        background: #F9FAFC;
        font-size: 16px;
        border-radius: 15px;
        border: 1px solid #E1E1E1;
        // 文章分类顶部
        .top {
          height: 170px;
          display: flex;
          align-items: center;
          .text {
            margin-left: 33px;
            color: #ffffff;
            .english {
              font-size: 14px;
              font-weight: 100;
              margin-bottom: 13px;
            }
            .title {
              font-size: 18px;
              font-weight: bold;
            }
            .label {
              font-size: 18px;
              font-weight: 100;
            }
          }
        }
        // 查看更多按钮
        .more-btn {
          margin: 5px 10px 0;
          display: flex;
          justify-content: flex-end;
          color: #666666;
          font-size: 14px;
        }
        .more-btn:hover {
          color: #333333;
          cursor: pointer;
        }

        // 文章列表
        .bottom {
          font-size: 16px;
          // height: 156px;
          display: flex;
          align-items: center;
          margin: 0 33px;
          margin-top: 5px;
          .articleItem {
            display: flex;
            margin: 10px 0;
            .createTime {
              color: #666666;
              font-weight: 100;
              width: 120px;
              overflow:hidden;
              text-overflow:ellipsis;
              white-space:nowrap    
            }
            .articleTitle {
              width: 220px;
              overflow:hidden;
              text-overflow:ellipsis;
              white-space:nowrap          
            }
          }
  
          .articleItem:hover {
            cursor: pointer;
          }

          // 没有文章提示语
          .haveNotArticle {
            color: #666666;
          }
        }
      }
    }
  }
  
  .footer {
    .footer_contain {
      padding: 2.2vw 0 !important;
  
      .contain_item {
        white-space: nowrap;
  
        &:nth-child(6) {
          .footer_title:nth-child(4) {
            text-indent: 0 !important;
          }
        }
  
        &:last-of-type {
          background: #fff;
  
        }
      }
    }
  
    span {
      display: inline-block;
    }
  }
  
  .newBanner {
    position: relative;
  }
  
  .container3 {
    position: relative;
    width: 100%;
    background-color: #f4f4f4;
  }
  
  .poster {
    width: 100%;
    position: relative;
    color: #fff;
  
    img {
      width: 100%;
    }
  
    h3 {
      position: absolute;
      top: 44%;
      width: 100%;
      text-align: center;
      font-size: 2.42vw;
      font-weight: bold;
    }
  
    p {
      position: absolute;
      top: 55%;
      width: 100%;
      text-align: center;
      font-size: 3.13vw;
    }
  
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid #fff;
      background: none;
      box-sizing: border-box;
  
      &.swiper-pagination-bullet-active {
        background: #fff;
        border: none;
      }
    }
  }
  
  .small_title {
    font-size: 2vw;
    text-align: center;
    font-weight: bold;
  }
  
  .small_desc {
    font-size: 1.25vw;
    color: #666666;
    line-height: 1.5;
    text-align: center;
    margin-top: 10px;
  }
  
  .flex-row {
    display: flex;
    justify-content: space-around;
  }
  
  .flex-row2 {
    display: flex;
  
  }
  
  .flex-col {
    display: flex;
    flex-direction: column;
  
  }
  
  .skill_item {
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
  
    span {
      font-size: 30px;
    }
  }
  
  .content_img {
    width: 100%;
    margin-top: 100px;
  }
  
  .content2 {
    position: relative;
    margin-top: 70px;
    color: #fff;
  
    img {
      width: 100%;
    }
  
    .content2_vs {
      position: absolute;
      top: 49%;
      left: 47.7%;
      font-size: 2.35vw;
    }
  
    .content2_title {
      position: absolute;
      top: 4%;
      left: 12%;
      width: 80%;
      display: flex;
      font-size: 1.46vw;
  
      h3:first-child {
        margin-right: 51%;
      }
    }
  
    .content2_desc {
      position: absolute;
      top: 24%;
      left: 19%;
      width: 80%;
      display: flex;
      font-size: 1.25vw;
  
      li {
        width: 7vw;
        text-align: center;
        margin-bottom: 3.2vw;
      }
  
      ul:first-child {
        margin-right: 55%;
      }
    }
  
    .rule {
      position: absolute;
      text-align: center;
  
      h3 {
        font-size: 1.25vw;
        margin-bottom: 2vh;
      }
  
      p {
        font-size: 1vw;
        color: #666666;
      }
  
      &:first-child {
        top: 1%;
        left: 10%;
      }
  
      &:nth-child(2) {
        top: 27%;
        left: -8%;
      }
  
      &:nth-child(3) {
        top: 49%;
        left: -9%;
      }
  
      &:nth-child(4) {
        top: 75.7%;
        left: 8%;
      }
  
      &:nth-child(5) {
        top: 95.3%;
        left: 46.5%;
      }
    }
  }
  
  .case {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 7vw;
  
    .case_item_wrap {
      position: relative;
      width: 22%;
      margin-bottom: 2vw;
  
      .case_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 1vw 0;
        border-radius: 1.04vw;
        border: solid 0.05vw #e1e1e1;
  
        img {
          width: 5.33vw;
        }
  
        h3 {
          font-size: 1.25vw;
          color: #333333;
          margin: 1.8vw 0 2.8vw;
        }
  
        p {
          font-size: 1.04vw;
          color: #666666;
        }
      }
  
      .case_item_code {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        box-shadow: 0vw 0.52vw 1.44vw 0.13vw rgba(102, 102, 102, 0.2);
        border-radius: 1.04vw;
  
        img {
          width: 100%;
        }
      }
    }
  }
  
  
  
  .container2 {
    width: 100%;
    padding-top: 7vw;
  
    .gray_wrapper {
      width: 100%;
      background-color: #f4f4f4;
    }
  
    .center_pic {
      width: 70%;
      margin: 4vw auto 0;
      display: flex;
      align-items: center;
      padding: 2.4vw 0;
  
      img {
        width: 50%;
      }
    }
  }
  
  .center_left {
    text-align: center;
    margin-right: 6.5vw;
  
    h3 {
      font-size: 2.4vw;
      color: #333333;
      font-weight: bold;
    }
  
    p {
      font-size: 1.25vw;
      color: #666666;
      line-height: 1.56vw;
      margin-top: 1.6vw;
    }
  }
  
  .for_shop_left {
    display: flex;
    flex-wrap: wrap;
  
    .shop_item {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4vw;
  
      img {
        width: 4.95vw;
      }
  
      h3 {
        font-size: 1.56vw;
        color: #333333;
        margin: 1.3vw 0 0.9vw;
      }
  
      p {
        font-size: 1.04vw;
        line-height: 1.56vw;
        color: #666666;
        text-align: center;
      }
    }
  }
  
  .active_footer {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.56vw;
    z-index: 9;
    margin-top: 5vw;
  
    img {
      width: 16vw;
    }
  
    .ask_online {
      padding: 0.4vw 1.5vw;
      background-color: #f1536e;
      box-shadow: 0vw 0.42vw 0.96vw 0.08vw rgba(241, 83, 110, 0.2);
      border-radius: 28px;
      color: #fff;
      margin-left: 2vw;
      cursor: pointer;
    }
  
    .get_plan {
      padding: 0.4vw 1.5vw;
      background-color: #fda006;
      box-shadow: 0vw 0.42vw 0.96vw 0.08vw rgba(253, 160, 6, 0.2);
      border-radius: 28px;
      color: #fff;
      margin-left: 2vw;
      cursor: pointer;
    }
  }
  
  .footer {
    width: 100%;
    background-color: #4c5064;
    padding: 1.5vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #999999;
    font-size: 1.25vw;
  
    // background: url(../../img/矩形 822.png) no-repeat;
    // background-size: 100% 100%;
    img {
      // width: 11vw;
    }
  
    p {
      text-align: center;
  
    }
  
    .bottom_navs {
      margin: 2.3vw 0;
  
      a {
        position: relative;
        padding-right: 20px;
        margin-right: 20px;
        color: #999999;
  
        &.b_nav_border::after {
          position: absolute;
          content: " ";
          right: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background: #999;
        }
      }
    }
  }
  
  .fix_bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
  }
  
  .connect_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .connect {
    background: #ffffff;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    padding: 67px 73px 0 65px;
    bottom: 0;
    height: 100%;
    min-height: 100%;
    z-index: 9;
    font-size: 20px;
  
    .img_close {
      position: absolute;
      right: 10%;
      top: 7%;
    }
  
    .top {
      color: #535252;
      margin-bottom: 30px;
    }
  
    p,
    .title {
      margin-bottom: 30px;
    }
  
    .bottom {
      display: flex;
      flex-direction: column;
    }
  
    input {
      display: block;
      width: 320px;
      height: 40px;
      border: 1px solid #bfbfbf;
      margin-bottom: 21px;
      padding-left: 8px;
      box-sizing: border-box;
    }
  
    .submit {
      display: block;
      width: 320px;
      height: 40px;
      background: #3d8eff;
      color: #fff;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      cursor: default;
    }
  }
  
  .product_img {
    width: 100%;
  }
  
  .right_side {
    position: fixed;
    right: 50px;
    top: 33%;
    z-index: 9;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
  
    .right_item {
      display: block;
      position: relative;
      width: 68px;
      height: 68px;
      cursor: pointer;
  
      img {
        width: 100%;
      }
  
      .zixun {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        font-size: 1.04vw;
        color: #fff;
      }
  
      .right_wrapper {
        position: absolute;
        top: -115px;
        left: -100%;
        transform: translateX(-76%);
        width: 232px;
        height: 190px;
        z-index: 9;
        background: url('https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/2c7067aa-df70-4d1f-b967-9a7f463ac0fb.png') no-repeat center center;
  
        .right_wrap_info {
          position: absolute;
          top: 0;
          left: 0;
          width: calc(~'100% - 15px');
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
  
          >img {
            width: 60px;
          }
  
          >i {
            font-size: 14px;
            color: #999;
            padding: 12px 0;
            border-bottom: 1px solid #eee;
            margin-bottom: 18px;
            width: 134px;
            text-align: center;
          }
  
          >span {
            font-size: 24px;
            color: #333;
            font-weight: 600;
          }
        }
  
        p {
          color: #333;
          font-size: 1.25vw;
          text-align: left;
          width: 100%;
        }
      }
    }
  }
  
  .form {
    width: 19vw;
    margin: 1vw auto 0;
  
    .icon {
      position: absolute;
      left: 4px;
      top: 10px;
      width: 20px;
      height: 20px;
    }
  
    .captcha {
      position: absolute;
      right: 0;
      top: 5px;
    }
  
    .mg_robot {
      width: 16.25vw;
      height: 2.75vw;
      margin: 0.4vw auto 1.2vw;
  
      .mg_robot_link {
        position: relative;
        color: #167cff;
        font-size: 0.875vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
  
        .mg_robot_arrows {
          width: 0.6vw;
          margin-left: 8px;
        }
      }
    }
  }
  
  .develop {
    .poster {
      width: 100%;
      position: relative;
      color: #fff;
  
      .desc_container {
        position: absolute;
        width: 100%;
        height: 100%;
        // left: -20%;
        top: 0%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
  
        h3 {
          position: static;
          font-size: 2.8vw;
        }
  
        p {
          position: static;
          font-size: 1vw;
  
        }
      }
  
      .poster_tips {
        display: flex;
        align-items: flex-end;
        margin-top: 3vw;
  
        .tip_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 0.8vw;
  
          p {
            font-size: 0.7vw;
            margin: 1vw 0 0;
          }
  
          img {
            height: 2vw;
            width: 2vw;
          }
        }
      }
    }
  
    .zixun_btn {
      padding: 1.2vw 4.38vw;
      color: #fff;
      border: 1px solid #fff;
      margin-top: 5.8vw;
      cursor: pointer;
    }
  
    .more_dingzhi {
      width: 62.5vw;
      display: flex;
      margin: 3vw auto 0;
  
      .dingzhi_dev {
        width: 19.43vw;
        border: 1px solid #999;
  
        .dev_up {
          width: 100%;
          height: 5.45vw;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #666666;
          font-size: 1.56vw;
  
          img {
            width: 2.3vw;
            margin-right: 0.7vw;
          }
        }
  
        .dev_desc {
          padding: 3vw 2vw;
          font-size: 1.04vw;
          display: flex;
          flex-direction: column;
          text-align: center;
        }
  
        .dev_up_on {
          color: #fff;
          background-color: #3da5f4;
        }
      }
  
      .dingzhi_dev_on {
        flex: 1;
        box-shadow: 0vw 0.26vw 0.63vw 0.16vw rgba(61, 165, 244, 0.15);
        border: none;
      }
    }
  
    .dev_plan_wrap {
      width: 100%;
      padding-top: 7vw;
  
      .dev_plan {
        position: relative;
        width: 100%;
        margin-top: 3vw;
  
        img {
          width: 100%;
          display: block;
  
          &.img_on {
            animation: showimg 1s linear normal;
          }
  
          @keyframes showimg {
            0% {
              opacity: 0.3;
            }
  
            60% {
              opacity: 0.6;
            }
  
            100% {
              opacity: 1;
            }
          }
        }
  
        .dev_plan_desc {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          display: flex;
  
          .plan_left {
            margin-left: 18vw;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 14vw;
  
            .plan_item {
              flex: 1;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #464646;
              color: #999999;
              font-size: 1.25vw;
              border-bottom: 1px solid #acacac;
              cursor: default;
              box-sizing: border-box;
  
              &:last-child {
                border-bottom: none;
              }
  
              &.plan_item_active {
                background: transparent;
                color: #fff;
              }
            }
          }
  
          .plan_right {
            margin-left: 2.9vw;
            padding-top: 3.5vw;
            color: #fff;
  
            &.tran_on {
              animation: showdesc 1s linear normal;
            }
  
            @keyframes showdesc {
              0% {
                margin-left: 0
              }
  
              100% {
                margin-left: 2.9vw;
              }
            }
  
            .plan_title {
              font-size: 2.5vw;
              margin-bottom: 2vw;
            }
  
            .plan_desc {
              font-size: 1.04vw;
              line-height: 2;
            }
  
            .plan_label {
              margin-top: 33%;
              font-size: 0.94vw;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
  
              .plan_label_item {
                padding: 0.6vw 2.3vw 0.6vw 0.9vw;
                margin-bottom: 1vw;
                border: 1px solid #fff;
              }
            }
          }
        }
      }
    }
  
    .dev_advantage {
      .can_do_nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        border-bottom: 3px solid #eee9e9;
  
        .nav_item {
          width: 33%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 6vw;
  
          border-bottom: 2px solid #fff;
  
          .nav_itemImg {
            width: 4.5vw;
  
            >img {
              display: block;
              width: 100%;
            }
          }
  
          span {
            font-size: 1.25vw;
            color: #333333;
            margin-left: 1vw;
          }
        }
      }
  
      display: flex;
      flex-direction: column;
  
      .tec_list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        margin-top: 40px;
  
        // padding: 0 20px;
        .tec_item:nth-child(n+4) {
          margin-top: 80px;
        }
  
      }
  
      .tec_item {
        width: 30%;
      }
  
      .tec_img {
        display: flex;
        justify-content: center;
        padding: 10px;
        margin-top: 20px;
  
        img {
          width: 60px;
          height: 60px;
        }
      }
  
      .tec_name {
        text-align: center;
        font-weight: bold;
      }
  
      .el-tabs__item {
        padding: 0 40px;
      }
  
      .tip {
        text-align: center;
        margin-top: 10px;
        font-size: 12px;
        color: #666666;
  
        span {
          border-left: 1px solid #cdcdcd;
          padding-left: 4px;
          padding-right: 4px;
        }
      }
  
      .tip span:first-child {
        border: none;
      }
  
      .team_box {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
  
        .team_item:nth-child(n+2) {
          margin-top: 40px;
        }
  
        .team_item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 40%;
  
          .team_img {
            text-align: center;
          }
  
          .team_title {
            text-align: center;
            font-weight: bold;
            font-size: large;
            margin: 20px 0;
          }
  
          .team_tip {
            text-align: center;
            font-size: 12px;
            color: #666666;
          }
        }
      }
  
      .quality_box {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 40px;
  
        .quality_item:nth-child(n+3) {
          margin-top: 40px;
        }
  
        .quality_item {
          width: 40%;
          display: flex;
          padding: 20px;
  
          .quality_img {
            display: flex;
            align-items: center;
            padding-right: 20px;
  
            img {
              display: block;
            }
          }
  
          .quality_title {
            font-weight: bold;
          }
  
          .quality_tip {
            margin-top: 10px;
            color: #666666;
            font-size: 12px;
          }
        }
      }
  
      .sale_sv_box {
        display: flex;
        justify-content: space-between;
  
        .sv_title {
          font-weight: bold;
          font-size: larger;
          margin: 30px 0;
        }
  
        .sv_tips {
          margin-top: 10px;
          color: #666666;
          font-size: 12px;
        }
      }
  
  
    }
  
    .dev_partner {
      padding: 4.5vw 0;
      background: #f4f4f4;
      margin-top: 3.9vw;
  
      .partner_wrap {
        width: 70%;
  
        .el-image {
          width: 23%;
          margin: 1%;
        }
  
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        // align-items: center;
  
        img {
          width: 100%;
          height: auto;
          margin-right: 2%;
          margin-bottom: 2vw;
  
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  
    .case_wrap {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 3.4vw;
  
      .case_item {
        position: relative;
        width: 23%;
        height: 263px;
        margin-right: 2%;
        margin-bottom: 30px;
        overflow: hidden;
        box-shadow: 0vw 0.26vw 0.7vw 0.08vw rgba(46, 46, 46, 0.1);
  
        &:nth-child(4n) {
          margin-right: 0;
        }
  
        .product_img {
          object-fit: contain;
        }
  
        .case_inside {
          position: absolute;
          top: 143px;
          left: 0;
          width: 100%;
          height: 100%;
          background: #fff;
          padding: 20px;
          box-sizing: border-box;
          font-size: 0.82vw;
  
          &.case_inside_onss {
            top: 0;
            animation: slideup 0.5s linear normal;
          }
  
          @keyframes slideup {
            0% {
              top: 143px
            }
  
            50% {
              top: 50px
            }
  
            100% {
              top: 0
            }
          }
  
          .case_logo {
            display: block;
            width: 50px;
            margin-bottom: 10px;
          }
  
          .inside_head {
            height: 78px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
  
          .inside_foot {
            margin-top: 20px;
          }
        }
      }
    }
  }
  
  .page_title {
    width: 100%;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    margin-bottom: 30px;
  }
  
  .page_title .line {
    width: 80px;
    height: 1px;
    background-color: #ffcc00;
    margin: 18px auto 10px;
  }
  
  .page_title h2 {
    font-size: 60px;
    color: #525252;
    opacity: 0.2;
  }
  
  .zh_title {
    position: absolute;
    top: 34px;
    width: 100%;
    text-align: center;
    font-size: 30px;
    color: #252525;
  }
  
  .index3 {
    .case {
      position: relative;
      width: 70%;
      margin: 50px auto 0;
      padding: 0 150px;
    }
  
    .next-img {
      position: absolute;
      right: 50px;
      top: 32%;
    }
  
    .pre-img {
      position: absolute;
      left: 50px;
      top: 32%;
    }
  }
  
  .goods_net {
    .poster {
      .desc_container {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        h4 {
          position: static;
          font-style: normal;
          font-size: 2.5vw;
        }
  
        p {
          font-size: 3.13vw;
        }
      }
    }
  
    .flex-row {
      justify-content: space-between;
    }
  
    .image_wrap {
      width: 30%;
      position: relative;
      border-radius: 20px;
  
      img {
        width: 100%;
      }
  
      h4 {
        font-size: 1.25vw;
        font-weight: normal;
        color: #333333;
        position: absolute;
        top: 50%;
        left: 10%;
      }
  
      p {
        font-size: 1.04vw;
        font-weight: normal;
        color: #333333;
        line-height: 2;
        position: absolute;
        top: 60%;
        left: 10%;
        width: 80%;
      }
    }
  
    .net_title {
      position: absolute;
      top: 10%;
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 2.4vw;
    }
  
    .container3 {
      background: #fff;
    }
  
    .net_desc {
      position: absolute;
      top: 42%;
      left: 65%;
      width: 25.99vw;
      color: #fff;
  
      h4 {
        font-size: 1.56vw;
        margin-bottom: 30px;
      }
  
      p {
        font-size: 1.04vw;
      }
    }
  
    .net_swiper {
      margin-top: 60px;
  
      img {
        width: 100%;
      }
  
      .net_swiper_desc {
        position: absolute;
        bottom: 10%;
        left: 10%;
        width: 37.45vw;
        color: #fff;
  
        h4 {
          font-size: 1.25vw;
          margin-bottom: 2vw;
        }
  
        p {
          font-size: 1.04vw;
          line-height: 1.5;
        }
      }
    }
  
    .aiot_left {
      width: 45%;
    }
  
    .aiot_right {
      margin-left: 5vw;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      .aiot_title {
        font-size: 1.25vw;
        color: #333333;
      }
  
      .aiot_desc {
        display: flex;
        font-size: 1.04vw;
        color: #666666;
        line-height: 2;
  
        .aiot_desc_right {
          flex: 1;
        }
      }
  
      .aiot_more {
        display: block;
        width: 9.17vw;
        height: 2.03vw;
        line-height: 2.03vw;
        background-color: #323b56;
        box-shadow: 0px 10px 22.5px 2.5px rgba(40, 46, 68, 0.2);
        border-radius: 19.5px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        margin-bottom: 3vw;
      }
    }
  
    .net_flex_wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 50px;
  
      .net_case {
        width: 23%;
        text-align: center;
        background: #fff;
        margin-bottom: 1vw;
  
        &.selected {
          box-shadow: 0px 5px 16px 4px rgba(37, 42, 72, 0.1);
        }
  
        .net_case_name {
          font-size: 1.25vw;
          color: #333333;
          margin: 1vw;
        }
  
        .net_case_look {
          font-size: 1.04vw;
          color: #323b56;
          margin-bottom: 1.25vw;
        }
      }
  
      .service_wrap {
        width: 18%;
        height: 5.9vw;
        display: flex;
        align-items: center;
        justify-content: center;
  
        .service_item {
          max-width: 100%;
        }
      }
    }
  }
  
  .new_retail {
    .poster {
      .desc_container {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        h3 {
          position: static;
          font-style: normal;
          font-size: 3.13vw;
        }
  
        p {
          font-size: 2.3vw;
          margin-top: 3vw;
        }
      }
    }
  
    .section_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 12.45vw;
      // h4 {
      //     font-size: 1.56vw;
      //     color: #333333;
      //     margin: 1.2vw 0;
      // }
      // p {
      //     font-size: 1.25vw;
      //     color: #666666;
      //     text-align: center;
      // }
      // .section_logo {
      //     width: 5.36vw;
      //     height: 5.42vw;
      //     text-align: center;
      //     img {
      //         max-width: 100%;
      //     }
      // }
    }
  
    .retail_gs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5vw 0 3vw;
      font-size: 1.25vw;
      color: #333333;
  
      img {
        width: 1.93vw;
        margin: 0 1.5vw;
      }
    }
  
    .for_tips {
      width: 10.21vw;
      height: 2.5vw;
      background-color: #29abe2;
      border-radius: 24px;
      font-size: 1.25vw;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1.5vw auto 3vw;
  
      .circle_tips {
        width: 0.42vw;
        height: 0.42vw;
        background-color: #ffffff;
        border-radius: 50%;
        margin: 0 0.36vw;
      }
    }
  
    .flex-row {
      justify-content: space-between;
      align-items: center;
  
      img {
        width: 50%;
      }
    }
  
    .tips_desc {
      width: 26.46vw;
      margin-left: 2vw;
  
      h4 {
        color: #333333;
        font-size: 1.25vw;
        margin-bottom: 1.25vw;
      }
  
      p {
        font-size: 1.04vw;
        color: #666666;
      }
    }
  
    .flex-end {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 4vw 0 6vw;
  
      .tips_desc {
        margin-left: 0;
        margin-right: 2vw;
      }
  
      img {
        width: 40%;
      }
    }
  
    .retail_develop {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 25%;
  
      img {
        width: 3.4vw;
      }
  
      h4 {
        font-weight: bold;
        font-size: 1.56vw;
        color: #333333;
        margin: 2vw 0 1.5vw;
      }
  
      p {
        font-size: 0.94vw;
        color: #666666;
        line-height: 1.5;
      }
    }
  
    .retail_bottom {
      display: flex;
      justify-content: center;
      margin: 5vw 0;
  
      .retail_bottom_item {
        width: 6.63vw;
        margin-right: 6vw;
        font-size: 1vw;
        text-align: center;
  
        &:last-child {
          margin-right: 0;
        }
  
        .retail_bottom_wrap {
          width: 4.79vw;
          height: 4.79vw;
          border-radius: 50%;
          margin: 0 auto 1vw;
          background: #3da5f4;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
      }
    }
  }
  
  .case_wrapper {
    padding: 4vw 0;
    display: flex;
    justify-content: space-between;
  
    .case_wrapper_left {
      padding: 1vw 1vw 1vw 0;
      border-right: 1px solid #cdcbcb;
      display: flex;
      flex-direction: column;
  
      .case_wrapper_item {
        display: flex;
        align-items: center;
        margin-bottom: 2.3vw;
        cursor: default;
  
        &:last-child {
          margin-bottom: 0;
        }
  
        .case_wrapper_logo {
          width: 8.3vw;
          height: 3vw;
          margin-right: 1.5vw;
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 1px #999999;
          background-color: #ffffff;
          padding: 0.36vw 1vw;
  
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
  
        p {
          color: #999999;
          font-size: 1.04vw;
        }
  
        &.selected {
          .case_wrapper_logo {
            box-shadow: 0px 5px 16px 4px rgba(37, 42, 72, 0.1);
            border: none;
          }
  
          p {
            color: #167cff;
          }
        }
      }
    }
  
    .case_wrapper_right {
      position: relative;
      flex: 1;
      margin-left: 3vw;
  
      img {
        width: 100%;
      }
  
      p {
        position: absolute;
        width: 70%;
        bottom: 12%;
        left: 5%;
        line-height: 1.5;
        font-size: 1.04vw;
        color: #ffffff;
      }
    }
  }
  
  .new_S2B2C {
    .poster {
      .desc_container {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        h4 {
          position: static;
          font-style: normal;
          font-size: 2.5vw;
        }
  
        p {
          font-size: 3.13vw;
        }
      }
    }
  
    .s2b_flex {
      margin-top: 6vw;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .s2b_item {
        text-align: center;
        position: relative;
        font-size: 1.25vw;
        margin: 0 1vw;
  
        img {
          max-width: 100%;
        }
  
        p {
          margin-top: 1vw;
          white-space: nowrap;
        }
  
        .s2b_item_tips {
          position: absolute;
          width: 100%;
          top: -2vw;
          text-align: center;
        }
      }
  
      .s2b_wrap {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 1.25vw;
        color: #ffffff;
        min-width: 26vw;
  
        .s2b_wrap_item {
          width: 6.67vw;
          height: 6.67vw;
          border-radius: 50%;
          background-color: #3da5f4;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-bottom: 1.5vw;
        }
      }
    }
  
    .s2b_work {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      width: 20.05vw;
      background-color: #ffffff;
      box-shadow: 0px 5px 16px 4px rgba(37, 42, 72, 0.1);
      border-radius: 10px;
      padding: 5vw 0 3vw;
      box-sizing: border-box;
  
      img {
        max-width: 4.58vw;
        max-height: 4.38vw;
      }
  
      h4 {
        font-size: 1.25vw;
        color: #333333;
        margin: 1.4vw 0 2vw;
        font-weight: bold;
      }
  
      p {
        font-size: 1.04vw;
        color: #666666;
      }
    }
  
    .s2b_container {
      background: url("https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/7981776d-fdef-4cca-afb3-715266e834b2.png") 100% 100% no-repeat;
      background-size: 100% 100%;
      padding: 3vw 0 5vw;
      margin-top: 8vw;
  
      .small_title {
        color: #fff;
      }
  
      .s2b_content {
        width: 60%;
        margin: 3vw auto 0;
  
        .s2b_content_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1.5vw 3.5vw 2.5vw;
          background-color: #1e2435;
          border-radius: 10px;
          color: #fff;
          margin-bottom: 0.9vw;
  
          &:last-child {
            margin-bottom: 0;
          }
  
          h4 {
            font-size: 1.25vw;
            color: #ffffff;
            margin-bottom: 1.3vw;
          }
  
          p {
            font-size: 1.04vw;
            color: #ffffff;
          }
  
          img {
            width: 3.6vw;
          }
        }
      }
    }
  
    .s2b_bottom {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 6vw 0;
  
      .s2b_bottom_item {
        width: 30%;
        border: solid 1px #999999;
        margin: 1vw 0;
  
        img {
          width: 100%;
        }
  
        .s2b_bottom_txt {
          width: 100%;
          height: 4.69vw;
          line-height: 4.69vw;
          text-align: center;
        }
      }
    }
  }
  
  .active {
    border-bottom: 2px solid #167cff !important;
  }
  .changeitem{
    width: 410px;
    height: 134px;
    background: #F8FAFC;
    border-radius: 10px;
    padding: 60px 0px 60px 41px;
    box-sizing: border-box;
    transition: all .3s linear;
  }
  .jinyingleft{
    display: flex;
    flex-direction: column;
    width: 410px;
  
    .item{
      display: flex;
      align-items: center;
      margin-bottom: 44px;
      padding-left:41px ;
      cursor: pointer;
      transition: all .3s linear;
      span{
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #666666;
      }
      .sapn-active {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
      }
    }
  }
  .jinyingright{
    .box{
      width: 766px;
      height: 570px;
      margin-left: 68px;
      position: relative;
      img{
        width: 766px;
        // height: 570px;
        height: auto;
      }
      .btn {
        width: 222px;
        height: 66px;
        background: #04cb94;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 66px;
        text-align: center;
        cursor: pointer;
        border-radius: 40px;
        position: absolute;
        bottom: 10px;
        left: 271px;
      }
    }
  }
  .items{
    display: flex;
    flex-direction: column;
    span{
      margin-left: 14px;
    }
  }
  .tos{
    font-size: 18px !important;
    color: #999 !important;
    margin-top: 20px;
    font-weight: normal !important;
  }
  .icons{
    width: 32px;
    height: 32px;
  }


  // 弹窗样式
  /deep/ .el-dialog {
    background: transparent !important;
    box-shadow: none !important;
  }

  .dialog-box {
    background-image: url("https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/38963e3b-96df-493d-8e33-8c15a2ad3959.png");
    background-size: 100% 100%;
    width: 1002px;
    height: 602px;
    position: relative;
  }
  .dialog-close {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 10px 20px 0px 0px;

    img {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
  }
  .dialog-content {
    position: absolute;
    top: 120px;
    right: 100px;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    width: 420px;
    height: 64px;
  }
  .dialog-btn {
    width: 200px;
    height: 60px;
    background: #000000;
    border-radius: 30px;
    position: absolute;
    cursor: pointer;
    top: 460px;
    right: 200px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #f7f7f9;
    line-height: 60px;
    text-align: center;
  }
  // 弹窗样式结束
</style>