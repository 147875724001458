/**
 * 自定义中文简体语言包
 */

const zh = {
  "nav.home": "首页",
  "nav.beautyIndustryMiniProgram": "美业小程序",
  "nav.beautyChain": "美业连锁",
  "nav.beautyDistribution": "美业分销",
  "nav.beautyAloT": "美业AloT",
  "nav.customerStories": "客户案例",
  "nav.aboutUs": "关于我们",
  "nav.tryFree": "免费试用",

  "footer.part1_title1": "趋势美业，美业生意增长专家",
  "footer.part1_text1": "专注为美业品牌商提供社交电商、小程序、裂变分销系统、AI测肤算法、AI体质检测算法、新零售、智慧门店、供应链的美业一体化解决方",
  "footer.withContactUs": "与我们联系",
  "footer.part1_text2": "将有专人为你提供经营建议、解决方案、专业答疑等",
  "footer.phonePlaceholder": "请输入",
  "footer.submitBtn": "预约回电",
  "footer.contactUsTitle": "联系我们",
  "footer.consultation": "售前服务咨询",
  "footer.contactCustomerService": "联系客服",
  "footer.officialAccount": "趋势云公众号",
  "footer.companyName": "广州市趋势信息技术有限公司",
  "footer.icp": "粤ICP备18106639号-1",
  "footer.linkTitle1": "热门链接",
  "footer.linkTitle2": "解决方案",
  "footer.linkTitle3": "探索趋势美业",
  "footer.linkTitle4": "趋势系列产品",
  "footer.linkTitle1_label1": "趋势连锁",
  "footer.linkTitle1_label2": "AI肤质检测",
  "footer.linkTitle1_label3": "AI体质检测",
  "footer.linkTitle1_label4": "美业分销",
  "footer.linkTitle1_label5": "客户案例",
  "footer.linkTitle1_label6": "免费试用",
  "footer.linkTitle2_label1": "医美机构",
  "footer.linkTitle2_label2": "化妆品",
  "footer.linkTitle2_label3": "产后护理",
  "footer.linkTitle2_label4": "中医理疗",
  "footer.linkTitle2_label5": "舞蹈健身",
  "footer.linkTitle2_label6": "舞蹈健身",
  "footer.linkTitle3_label1": "关于我们",
  "footer.linkTitle3_label2": "新闻中心",
  "footer.linkTitle4_label1": "趋势云",
  "footer.linkTitle4_label2": "趋势美业",
  "footer.linkTitle4_label3": "趋势智能",
  "footer.phnoe_msg": "请输入手机号",
  "footer.phnoe_msg2": "请输入11位正确格式的手机号",
  "footer.subSuccess": "提交成功",
  "footer.sunFail": "提交失败",

  "home.f3_box_title": "一套软件解决全部经营需要",
  "home.f3_box_text": "美业连锁线上线下一体化经营解决方案",
  "home.f4_box_title": "连接一切美业品牌经营要素",
  "home.f4_box_text": "全方位深入解决美业品牌经营管理难题",
  "home.experienceNow": "立即体验",
  "home.f7_box_title": "黑科技拓客引流神器",
  "home.f7_box_text": "视觉 AI 技术，赋能健康产业及美业",
  "home.f6_box_title": "他们都选择趋势云",
  "home.f6_box_text": "了解更多案例 > ",
  "home.f8_box_title": "不仅提供软件，还有硬件的智能化升级",
  "home.f8_box_text": "软硬结合，加速美业门店数字化运营管理",
  "home.f5_box_title1": "为什么选择",
  "home.f5_box_title2": "趋势云",
  "home.f5_box_text": "深入业务细节，技术赋能美业商业模式运营落地",
  "home.learnMore": "了解更多 >",
  "home.f9_box_title": "趋势美业最新动态",
  "home.more": "更多",
  "home.noArticleList": "暂无相关文章",
  "home.bannerData_title1": "趋势美业",
  "home.bannerData_subtitle1": "美业连锁全栈式数字化经营解决方案",
  "home.bannerData_content1_1": "连锁门店店务管理+多触点私域运营+人工智能物联网赋能，",
  "home.bannerData_content1_2": "一站式搞定美业连锁门店运营管理",
  "home.bannerData_button1": "立即试用",
  "home.bannerData_title2": "美业连锁机构数字化转型口碑之选",
  "home.bannerData_subtitle2": "已帮助超1000家美业门店实现数字化转型、快速盈利",
  "home.bannerData_button2": "查看详情",
  "home.dataF2_title1": "美业小程序",
  "home.dataF2_content1": "链接客户，流量变现",
  "home.dataF2_title2": "美业连锁管理",
  "home.dataF2_content2": "多级连锁，加盟管理",
  "home.dataF2_title3": "美业分销",
  "home.dataF2_content3": "全民分销，业绩增长",
  "home.dataF2_title4": "美业AIOT",
  "home.dataF2_content4": "黑科技美业",
  "home.f8_wrap_h1": "测肤仪",
  "home.f8_wrap_p1": "肤质检测API嵌入测肤仪解读肤质状态，推荐护理方案，提高客户付费意愿。",
  "home.f8_wrap_h2": "台式收银机",
  "home.f8_wrap_p2": "高性能收银终端/专业芯片模组告诉运行/稳定流畅、可拓展",
  "home.f8_wrap_h3": "美肤仓",
  "home.f8_wrap_p3": "美白仓/美黑仓/理疗机/紫外光疗仓等设备联网，让操作更智能/更便捷",
  "home.f8_wrap_h4": "按摩仪",
  "home.f8_wrap_p4": "对接按摩仪/面罩灯/紫外消毒车设备，实现设备联网",
  "home.f8_wrap_h5": "小票打印机",
  "home.f8_wrap_p5": "高性能精工热敏打印头支持海量订单打印，操作维护省钱更省心",
  "home.f8_wrap_h6": "机器人",
  "home.f8_wrap_p6": "体态检测机器人/中医体质机器人，适用多种场景，黑科技触点为门店带来源源不断客流",
  "home.dataF4_title1": "预约排班",
  "home.dataF4_content1": "便捷化体验，智能化管理",
  "home.dataF4_title2": "在线预约：",
  "home.dataF4_content2": "顾客线上自助预约时间、技师，体验满意，到店率高",
  "home.dataF4_title3": "预约看板",
  "home.dataF4_title4": "排班管理",
  "home.dataF4_title5": "房间管理",
  "home.dataF4_title6": "获客引流",
  "home.dataF4_content6": "线上线下多渠道引流获客",
  "home.dataF4_title7": "小程序商城：",
  "home.dataF4_content7": "微信用户流量的超级流量入口",
  "home.dataF4_title8": "新人券 / 体验券",
  "home.dataF4_title9": "AI 测肤 / 体测",
  "home.dataF4_title10": "内容种草",
  "home.dataF4_title11": "成交转化",
  "home.dataF4_content11": "激活消费，业绩提升",
  "home.dataF4_title12": "秒杀：",
  "home.dataF4_content12": "限时限量秒杀，引发冲动消费",
  "home.dataF4_title13": "体验价",
  "home.dataF4_title14": "限时优惠券",
  "home.dataF4_title15": "活动抽奖",
  "home.dataF4_title16": "留存复购",
  "home.dataF4_content16": "锁客留客，业绩稳步增长",
  "home.dataF4_title17": "储值卡、医疗卡、套餐卡：",
  "home.dataF4_content17": "丰富卡项设计方案，吸引顾客办卡充值",
  "home.dataF4_title18": "积分体系",
  "home.dataF4_title19": "RFM智能分析",
  "home.dataF4_title20": "会员权益/会员关系",
  "home.dataF4_title21": "分销裂变",
  "home.dataF4_content21": "全民带货助力业绩增长",
  "home.dataF4_title22": "分享券：",
  "home.dataF4_content22": "通过微信赠券，分享好礼，挖掘老客户背后的人脉",
  "home.dataF4_title23": "拼团",
  "home.dataF4_title24": "砍价助力",
  "home.dataF4_title25": "分销系统",
  "home.dataF4_title26": "连锁经营",
  "home.dataF4_content26": "多门店统一管理，标准化快速扩张品牌",
  "home.dataF4_title27": "权限设置：",
  "home.dataF4_content27": "微信用户流量的超级流量入口",
  "home.dataF4_title28": "资源共享",
  "home.dataF4_title29": "营销规划",
  "home.dataF4_title30": "财务监控",
  "home.dataF4_title31": "数据报表",
  "home.dataF4_content31": "多维度经营数据分析，赋能精细化运营",
  "home.dataF4_title32": "会员报表：",
  "home.dataF4_content32": "会员卡耗、会员统计、会员画像一目了然",
  "home.dataF4_title33": "业绩报表",
  "home.dataF4_title34": "连锁报表",
  "home.dataF4_title35": "决策报表",
  "home.dataF5_title1": "深刻理解美业品牌经营核心",
  "home.dataF5_content1": "连接一切美业品牌经营要素，从会员、店务、营销、供应链一体化解决方案，真正一站式解决方案",
  "home.dataF5_title2": "海量客户积累，持续优化升级",
  "home.dataF5_content2": "海量客户积累，服务客户覆盖美妆、美容医院、中医诊疗、齿科诊所、舞蹈健身等美业类型",
  "home.dataF5_title3": "私域运营赋能门店增长获客",
  "home.dataF5_content3": "功能齐全的美业小程序，赋能品牌搞定拓-留-锁-升，链接客户，实现流量爆炸式增长与变现",
  "home.dataF5_title4": "17年行业经验强大品牌保障",
  "home.dataF5_content4": "强大稳定的产品研发团队，倾心陪伴，支持业务定制，满足各类功能各类场景的个性化需求",
  
  "home.dataF6_list1_obj1_name1": "阳光医美",
  "home.dataF6_list1_obj1_content1": "全国５Ａ级医疗美容机构，华南高端医美品牌， 医美行业龙头企业",
  "home.dataF6_list1_obj1_content1_2": ' “通过数字化手段，以社区种草、医美论坛、智慧供应链三大手法持续打造品牌数智零售业态。” ',
  "home.dataF6_list1_obj1_iconText1": "供应链",
  "home.dataF6_list1_obj1_iconText1_2": "种草社区",
  "home.dataF6_list1_obj1_iconText1_3": "医美论坛",

  "home.dataF7_list1_obj1_name1": "华邦制药",
  "home.dataF7_list1_obj1_content1": "国内皮肤临床用药及皮肤健康领域的龙头企业,拥有国内最完整的皮肤类药物产品线",
  "home.dataF7_list1_obj1_content1_2": ' “抓住“AI+皮肤药”新增长点，基于趋势云AI算法的优 势，实现AI技术与业务逻辑的完美融合。” ',
  "home.dataF7_list1_obj1_iconText1": "AI测肤",
  "home.dataF7_list1_obj1_iconText1_2": "美丽商城",
  "home.dataF7_list1_obj1_iconText1_3": "肤质档案",

  "home.dataF6_list1_obj1_name2": "韩妃医美",
  "home.dataF6_list1_obj1_content2": "上市医美，广东TOP整形美容三级医院，实现 全国4城6院品牌连锁规模化经营",
  "home.dataF6_list1_obj1_content2_2": ' “深度围绕企业数字化布局，搭建健康美容垂直电商生态平台，实现品牌业绩增长新突破。” ',
  "home.dataF6_list1_obj1_iconText2": "连锁＋微店",
  "home.dataF6_list1_obj1_iconText2_2": "服务预约",
  "home.dataF6_list1_obj1_iconText2_3": "在线直播",

  "home.dataF6_list1_obj1_name3": "洗脸猫",
  "home.dataF6_list1_obj1_content3": "创新打造“轻美容洗脸吧”，专注面部洗护服务 ，全国门店已超500家",
  "home.dataF6_list1_obj1_content3_2": ' “以’轻美容+新零售’重新定义经营场景，打通线上线下渠道，帮助品牌实现快速招商加盟。” ',
  "home.dataF6_list1_obj1_iconText3": "线上商城",
  "home.dataF6_list1_obj1_iconText3_2": "会员分销",
  "home.dataF6_list1_obj1_iconText3_3": "代理商管理",

  "home.dataF6_list1_obj1_name4": "觅光",
  "home.dataF6_list1_obj1_content4": "腾讯、小米、真格基金等资本战略入股，国内美容 仪器TOP新消费品牌",
  "home.dataF6_list1_obj1_content4_2": ' 通过AI技术升级产品体验，打破传统营销困局，月销额持续增长，创造超3亿的业绩神话。" ',
  "home.dataF6_list1_obj1_iconText4": "AI测肤",
  "home.dataF6_list1_obj1_iconText4_2": "商品推荐",
  "home.dataF6_list1_obj1_iconText4_3": "皮肤档案",

  "home.dataF6_list1_obj2_name1": "漾澜",
  "home.dataF6_list1_obj2_content1": "瑞士科技护肤品牌，与Clinique La Prairie(CLP)  长期合作，以技术领先全球抗衰老领域",
  "home.dataF6_list1_obj2_content1_2": ' “用’分销’实现大范围覆盖、高精准锁定，帮助品牌快速触达客户，引爆品牌业绩新增量。” ',
  "home.dataF6_list1_obj2_iconText1": "APP商城",
  "home.dataF6_list1_obj2_iconText1_2": "商学院",
  "home.dataF6_list1_obj2_iconText1_3": "二级分销",

  "home.dataF6_list1_obj2_name2": "美众联",
  "home.dataF6_list1_obj2_content2": "美博会会长马娅牵头，30+企业联合打造的中国 首个美妆产业共享采购平台",
  "home.dataF6_list1_obj2_content2_2": ' “打造三方供需配方服务平台，共享供应链资源，提升整体效益，释放美业强大聚合力。“ ',
  "home.dataF6_list1_obj2_iconText2": "供应链系统",
  "home.dataF6_list1_obj2_iconText2_2": "原料商城",
  "home.dataF6_list1_obj2_iconText2_3": "推荐配方",

  "home.dataF6_list1_obj2_name3": "美健集",
  "home.dataF6_list1_obj2_content3": "生物科技护肤品牌，集团以生物科技为根本，布局大健康生态产业链",
  "home.dataF6_list1_obj2_content3_2": ' “以数字化新引擎，打造智慧美业连锁加盟商城平台，构建数字化、规范化服务应用场景。” ',
  "home.dataF6_list1_obj2_iconText3": "连锁加盟",
  "home.dataF6_list1_obj2_iconText3_2": "服务预约",
  "home.dataF6_list1_obj2_iconText3_3": "会员分销",

  "home.dataF6_list1_obj2_name4": "美利康",
  "home.dataF6_list1_obj2_content4": "大型高新光学设备企业，全国光学美容领域领跑者，主营产品为健康美白舱、光动力理疗舱、日光浴机（美黑机）",
  "home.dataF6_list1_obj2_content4_2": ' “设备联网+硬件智能化升级，助力品牌打造更容易被用户感知到的科技体验。” ',
  "home.dataF6_list1_obj2_iconText4": "AI测肤",
  "home.dataF6_list1_obj2_iconText4_2": "AI体质检测",
  "home.dataF6_list1_obj2_iconText4_3": "设备联网/控制",

  "home.dataF6_list1_obj3_name1": "润泽",
  "home.dataF6_list1_obj3_content1": "提供祛斑、祛皱高端美容服务，主打科技护肤，目前 线下已有200家门店",
  "home.dataF6_list1_obj3_content1_2": ' “深度围绕品牌需求，打造私域运营矩阵+门店联营一站式解决方案，为线下门店赋能。” ',
  "home.dataF6_list1_obj3_iconText1": "AI检测",
  "home.dataF6_list1_obj3_iconText1_2": "预约服务",
  "home.dataF6_list1_obj3_iconText1_3": "门店联营",

  "home.dataF6_list1_obj3_name3": "谷雨",
  "home.dataF6_list1_obj3_content3": "谷雨沿袭《黄帝内经》“春生夏长秋收冬藏”的 四季养生原则，提出了“依时依令，有效护肤”的护肤 理念，倡导顺应自然时节的更替和变化来做到真正的有效护肤。",
  "home.dataF6_list1_obj3_content3_2": ' “以AI测肤小程序实现“量肤定制”“因人护肤”理念， 撬动私域会员增长。” ',
  "home.dataF6_list1_obj3_iconText3": "AI测肤",
  "home.dataF6_list1_obj3_iconText3_2": "会员商城",
  "home.dataF6_list1_obj3_iconText3_3": "推荐套餐",

  "home.dataF7_title1": "肤质检测",
  "home.dataF7_content1": "配合摄像头，从肤色、肤龄、毛孔光滑度等多角度分析出肤质状况，并依据分析结果，智能推荐一对一美丽方案",
  "home.dataF7_title2": "体质检测",
  "home.dataF7_content2": "智能机器人实现望-闻-问-切中医四诊，快速辨别中医体质，推荐养生方案",
  "home.dataF7_title3": "体型检测",
  "home.dataF7_content3": "获得人体核心关键点坐标、通过计算肩部、脊柱、骨盆、腿部等体态数据，快速给到用户专业的体态报告",
  "home.articleCate_name1": "产品动态",
  "home.articleCate_name2": "签约动态",
  "home.articleCate_name3": "客户案例",
  "home.articleCate_label1": "专注 创新 极致",
  "home.articleCate_label2": "合作 共赢 发展",
  "home.articleCate_label3": "品质 口碑 趋势",

  "article.currentLocation": "当前位置：",
  "article.articlList": "文章列表",
  "article.articlPlaceholder": "请输入文章标题",
  "article.readFullArticle": "查看全文",
  "article.nan": "暂无",
  "article.relatedArticl": "相关文章",

  // huanggengsheng
  // 美业小程序
  "MiniProgram.bancontent1": "AI赋能的私域变现神器，深度裂变触达客户，智能营销搞定拓、留、锁、升",
  "MiniProgram.bancontent2": "高效管理 | 高效获客 | 高效变现",
  "MiniProgram.box1_content1": "黑科技拓客神器，高效引流裂变",
  "MiniProgram.box1_content2": "基于视觉AI技术，配合摄像头，从肤色、肤龄、毛孔、光滑度等多角度分析出肤质状况，并依据分析结果，智能推荐产品，构建从“流量”到“变现”的商业闭环。",
  "MiniProgram.box2_content1": "AI体质检测算法，赋能大健康行业智慧升级",
  "MiniProgram.box2_content2": "通过API或H5接口等形式，为合作伙伴提供APP、小程序的舌诊、肤质检测线上对接服务，智能推荐产品调理方案，应用场景覆盖药店、社区、养老院、旅游、医院、月子中心等。",
  "MiniProgram.box3_content1": "专家/服务/商品在线预约，合理优化资源利用",
  "MiniProgram.box3_content2": "商家可在商城发布可预约美容项目，引导客户在线自助预约，合理引导门店客流的同时，提高工位的使用率，优化员工资源配置，客户无需线下排队等待，体验感更高。",
  "MiniProgram.box4_title": "LBS定位",
  "MiniProgram.box4_content1": "基于LBS定位，精准引流附近顾客",
  "MiniProgram.box4_content2": "基于LBS定位，增加门店爆光率，辐射门店5公里，提升客流；帮助用户快速发现附近门店，帮助商家精准引流附近顾客，结合位置信息快速响应服务及商品，提高用户体验。",
  "MiniProgram.box5_content1": "可交互的美业社区分享生态，在私域里实现商业变现闭环",
  "MiniProgram.box5_content2": "短视频/图/文形式笔记触达客户，通过内嵌商品链接，从内容带动消费转化，打造变现闭环；为美容院、理疗馆、健身房、化妆品、宠物等美业商家提供了全新的营销拓客体系。",
  "MiniProgram.box6_title": "商城变现",
  "MiniProgram.box6_content1": "把美业门店开到顾客手机里，抢占亿万流量红利",
  "MiniProgram.box6_content2": "打造专属AI+小程序在线商城，打通线上线下业务，玩转私域流量，助力美业门店转型升级智慧新零售；AI测肤、AI体质、AI体态等AI黑科技“Buff”加持，助力商家快速实现流量销量双增长。",
  "MiniProgram.box7_title": "商城拖拽",
  "MiniProgram.box7_content1": "所见即所得，更快落地你的商业创意",
  "MiniProgram.box7_content2": "拖拽式自定义装修，定制+私有化部署，快速响应、敏捷迭代，随想即现、随需而变，帮助美业 商家快速搭建美业小程序商城，轻松实现不同业务场景。",
  // 美业连锁
  "MeiyeChain.bancontent1": "全面支持多种连锁经营模式，有条不紊加盟扩张",
  "MeiyeChain.bancontent2": "直营连锁 | 加盟连锁 | 直营加盟混合连锁",
  "MeiyeChain.box1_title": "管理连锁",
  "MeiyeChain.box1_content1": "支持多种连锁经营模式，直营加盟混营全搞定",
  "MeiyeChain.box1_content2": "总部拥有自定义层级权限管理，支持按总部、省、市、区、门店等区域配置，满足 直营、加盟、自由等多种连锁业态的经营需求。",
  "MeiyeChain.box2_title": "门店连锁",
  "MeiyeChain.box2_content1": "多门店统一管理，规范化经营加速品牌扩张",
  "MeiyeChain.box2_content2": "每个门店都有一个线上商城，总部可以将成熟的商业模式快速赋能给每一家门店，进行 线上营销、交易、或引流到店。",
  "MeiyeChain.box3_title": "资源连锁",
  "MeiyeChain.box3_content1": "门店数据互联互享，对比分析协同发展",
  "MeiyeChain.box3_content2": "商品、库存、会员、消费、人力互联互通，总部集中管理、多店共享使用，实现资源共享 和优势互补，全面提升门店整体经营效益及品牌影响力。",
  "MeiyeChain.box4_title": "营销连锁",
  "MeiyeChain.box4_content1": "营销方案全局规划，统一促销多店联动",
  "MeiyeChain.box4_content2": "系统设定的拼团、秒杀、砍价、优惠券，储值、积分、满减满赠，拓客小游戏等营销 玩法，均可同步给每一个门店，助力门店获客拉新。",
  "MeiyeChain.box5_title": "会员连锁",
  "MeiyeChain.box5_content1": "支持门店会员共享，实现经济效益最大化",
  "MeiyeChain.box5_content2": "总部统一管理会员信息，各门店均可共享总部所有的客户资源，支持会员跨门店消费， 系统自动识别会员身份、统计核销，方便各门店清分结算。",
  "MeiyeChain.box6_title": "数据连锁",
  "MeiyeChain.box6_content1": "运营数据透视分析，辅助管理者运营决策",
  "MeiyeChain.box6_content2": "各门店经营数据互联共享，可视化数据看板，方便总部一键查看、对比分析，全面掌控经 营核心数据，制定科学经营管理决策，提升门店运营效率",
  //美业分销
  "MeiyeDistribution.bantitle": "趋势美业分销系统",
  "MeiyeDistribution.bancontent1": "简单高效起量快，人人带货实实在在涨销量",
  "MeiyeDistribution.bancontent2": "多角色 | 多层级 | 多模式分销，助力商家搭建分销渠道拓宽销路",
  "MeiyeDistribution.box1_title": "分销模式",
  "MeiyeDistribution.box1_content1": "从0到1，快速搭建你的专属分销系统",
  "MeiyeDistribution.box2_title": "特色分销模式",
  "MeiyeDistribution.box2_content1": "不仅提供系统，还提供全套分销解决方案",
  "MeiyeDistribution.box2_content2": "支持设置分销等级、等级权重、不同身份角色权益，以及不同维度的用户行为所 获得“成长值”门槛，激励用户/代理商积极主动分销，科学培养粉丝粘性。",
  "MeiyeDistribution.box3_title": "分销结算",
  "MeiyeDistribution.box3_content1": "第三方权威金融清分系统，实现空中分账",
  "MeiyeDistribution.box3_content2": "系统自动分账，笔笔收益看得见，账面一目了然，一人也能掌管千人分销团队； 各方所得收益按比例入账，有效解决手续费、增值税、企业所得税等额外费用分摊问题。",
  "MeiyeDistribution.box4_title": "分销工具",
  "MeiyeDistribution.box4_content1": "多功能分销系统，用利益和规则帮你聚拢合伙人",
  "MeiyeDistribution.box4_content2": "分享券、任务卡、砍价助力、拼团、海报、链接100+分销工具任意搭配组合， 贴合不同模式需求，节省百万推广经费，让分销变得简单、可落地。",
  "MeiyeDistribution.box5_title": "分销管理",
  "MeiyeDistribution.box5_content1": "全流程跟踪管理，关注分销每个环节",
  "MeiyeDistribution.box5_content2": "可以根据业务需求制定相应的代理分佣规则，通过关系链条记录层级关系，对分销 进行全流程跟踪；可视化分销数据看板，随时随地监控管理。",


  // 关于我们
  "AboutUs.Facingthe": "面向全球美业&大健康企业",
  "AboutUs.Provideaone-stop": "提供一站式数字化管理与营销解决方案",
  "AboutUs.Freeexperience": "免费体验",
  "AboutUs.TrendMeiye": "趋势美业与您共创未来趋势",
  "AboutUs.TrendBeautyprovides": "趋势美业，专为美业（医疗美容、中医理疗、皮肤管理、舞蹈健身、齿科诊所、产后护理、足浴spa、 美体瘦身、美甲美睫、美容美发……）提供一站式运营管理解决方案。从拓客小程序，到连锁门店管理， 到美业供应链系统，以及趋势云倾力研发的人工智能+AI算法，以肤质识别、体质识别为切入口解决 行业获客引流挤持续变现的痛点及需求，结合美业物联网化解决方案，全面解决美业品牌商运营需求。",
  "AboutUs.wehaveaccumulated": "截至目前，我们已积累了包括阳光医美、韩妃医美、奈瑞儿、无限极、洗脸猫、觅光、华邦制药、普罗 亭、RARESEE、力海医药、觅龄轻医美、纤美纪、莱贺思宝（新加坡）、润泽美业、菠萝树、美利康、 漾兰（瑞士）、芬乐、华亚光电等数千家企业客户的成功服务经验。",
  "AboutUs.GlobalCustomers": "全球客户",
  "AboutUs.managementefficiency": "管理效率提升",
  "AboutUs.acquisitionefficiency": "获客效率提升",
  "AboutUs.operationalefficiency": "运营效率提升",
  "AboutUs.Navigationteam": "领航团队",
  "AboutUs.HuangLiangxing": "黃良興",
  "AboutUs.GeneralManager": "马来西亚（零售业）上市公司中国区总经理",
  "AboutUs.ListedCompanies": "东南亚（物流业）上市公司中国区总经理",
  "AboutUs.experienceoverdue": "逾期25年市场营销经验",
  "AboutUs.DirectorofOperations": "趋势美业国际事业发展中心运营总监",
  "AboutUs.BusinessSchool": "环球美业健康商学院校监及创始人",
  "AboutUs.Chairman": "香港美容协会主席",
  "AboutUs.Senior": "资深企业家及培训导师",
  "AboutUs.BeautyIndustry": "趋势美业运营策略专家",
  "AboutUs.JiaChao": "贾超",
  "AboutUs.ChiefTraditional": "主任中医师/医学博士/硕士研究生导师",
  "AboutUs.Academic": "国家非物质文化遗产贾立惠点穴疗法学术继承人",
  "AboutUs.Professor": "名老中医骨伤学陈基长教授学术继承人",
  "AboutUs.Technical": "趋势美业大健康领域技术专家",
  "AboutUs.WenGuihua": "文贵华",
  "AboutUs.Technology": "华南理工大学教授博士生导师",
  "AboutUs.Director": "华南理工机器学习与数据挖掘实验室主任",
  "AboutUs.Leader": "国内AI体质识别机器人应用领头人",
  "AboutUs.Member": "趋势美业AI智能产品开发专家组成员",
  "AboutUs.explorationprocess": "探索历程",
  "AboutUs.foundedTrend": "汪振俊先生创办趋势云，获批国家软件企业；",
  "AboutUs.Awarded": "荣获全国最佳应用服务商和最佳应用开发商；",
  "AboutUs.Thesupplychain": "供应链平台「多商网」上线，整合十万供应商资源，布局线上线下三十万分销商渠道",
  "AboutUs.Unitedwith": "联合华南理工大学人工智能实验室推出肤质检测系统；趋势美业初步孵化",
  "AboutUs.SouthChina": "联合华南理工大学推出人工智能体态检测系统、中医体质检测系统",
  "AboutUs.TheArtificialIntelligence": "被授牌“广东省人工智能中医工程技术研究中心”的“人工智能应用研发基地”",
  "AboutUs.TrendBeauty": "“趋势美业”SaaS产品借力人工智能算法收割医美、大健康、中医诊疗等泛美业市场",
  "AboutUs.TrendCloud": "趋势云（香港）（趋势云国际事业发展中心）正式运营，布局国际市场，为全球美业客户提供更专业的服务",
  "AboutUs.Embark": "开启探索之旅",
  "AboutUs.ContactUs": "联系我们",
  "AboutUs.Guangzhou": "广州丨总部",
  "AboutUs.ZhongshanAvenue": "广州市天河区中山大道西89号",
  "AboutUs.WestLadder": "华景软件园区B栋西梯5楼",
  "AboutUs.HongKong": "香港",
  "AboutUs.Foshan": "佛山",
  "AboutUs.FenjiangMiddle": "佛山市禅城区汾江中路215号",
  "AboutUs.Room": "创业大厦1008室",
  "AboutUs.phone": "电话",
  "AboutUs.mailbox": "邮箱",
  
  // 客户案例
  "Customer.Advanced": "先进企业实践案例",
  "Customer.businessgrowth": "美业商业增长的新方式",
  "Customer.Scheme": "方案免费领",
  "Customer.Deepening": "深入核心业务场景，趋势云助力奈瑞儿品牌门店提供引流、增收、管理等一站式解决方案",
  "Customer.Narielhas":"奈瑞儿选择与趋势云合作，旨在通过AI黑科技赋能+数字化连锁运营管理，融合业务场景、营销场景，搭建起覆盖“生活美容+医疗美容+大健康”的一站式美丽健康调理中心，助力品牌实现全方位的AI+数字化赋能，实现全域增长。",
  "Customer.completestory": "阅读完整故事",
  "Customer.Meiye": "美业",
  "Customer.SeekingLight": "觅光：月销持续“破亿”的秘密武器", 
  "Customer.SunshineMedical": "阳光医美：5A级医疗美容医院的数字升级路线", 
  "Customer.HuabangPharmaceuticals": "华邦制药：AI与皮肤临床药企的双剑合璧", 
  "Customer.ShampooCat": "洗脸猫：轻医美品牌就要用这套 招商玩法", 
  "Customer.Unlimited": "无限极：一个动作让直销员更专 业，更稳固", 
  "Customer.Proting": "普罗亭：体检中心引入AI体质检测？！", 
  "Customer.LaiheSibao": "莱贺思宝：新加坡莱贺思宝，海外 中医诊所的AI升级战略", 
  "Customer.Zeran": "泽然：4人小团队业绩增长100w的秘籍", 
  "Customer.Meilikang": "美利康：美业IoT，为客户提供优质服务体验", 
  "Customer.RARESEE": "RARESEE：雾化美容仪+AI测肤打造出稳固私域流量池", 
  "Customer.ChenNongfu": "陈农夫：一招搞定300+加盟商", 
  "Customer.OuTinger": "欧婷儿：暴增300w+业绩，复购率90%以上", 
  "Customer.Fenle": "芬乐：对标BabyCenter，盘活孕妈用户", 
  "Customer.PineappleTree": "菠萝树：800+母婴玩具体验馆的.创造力", 
  "Customer.Youkeyhui": "优键荟：如何做好60+岁用户的生意", 
  "Customer.Huamei": "华美：广州华美与趋势云达成合作， 共筑「智能中医美养」新高地",
  "Customer.Theimagecomes": "*图片来自客户提供或客户公开资料/客户官网，如有侵权请联系删除", 
  "Customer.Browsecustomer": "浏览客户案例", 

















  
  
  
  
};

export default zh; 